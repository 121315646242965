import React, { useState, useEffect, useRef } from 'react';
import Title from './Title.js';
import axios from 'axios';
import configData from './Config.json';
import moment from 'moment';

import Chat from './Chat';

import './Chats.css';

//revision 20240422

function Chats({ state, setState, }) {
    const [search, setSearch] = useState('');
    const [chats, setChats] = useState([]);

    const [options, setOptions] = useState(null);
    const [removeconfirmation, setRemoveConfirmation] = useState(null);
    const [loading, setLoading] = useState(true);

    const optionsRef = useRef(null);
    
    useEffect(() => {
        function handleClickOutside(event) {
            if (optionsRef.current && !optionsRef.current.contains(event.target)) {
                setOptions(false);
                setRemoveConfirmation(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [optionsRef]);

    useEffect(() => {
        getChats();
        clearChatsCount();
        const chatInterval = setInterval(() => {
            getChats();
        }, configData.GETCHATSINTERVAL);
        return () => clearInterval(chatInterval);
    }, []);

    function getChats() {
        setLoading(true)
        const data = {
            userid: state.user.userid
        };
        axios.post(configData.SECUREURL + configData.GETCHATS, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            if (res.data instanceof Array) {
                if (chats !== res.data) setChats(res.data)
            }
        }).catch(() => {
            console.log('Couldn\'t fetch chats')
        }).finally(() => {
            setLoading(false)
        })
    }

    function clearChatsCount() {
        const data = {
            userid: state.user.userid
        };
        axios.post(configData.SECUREURL + configData.CLEARCHATSCOUNT, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                }
            }
        }).catch(() => {
            console.error('Couldn\t clear chats count')
        })
    }

    const handleOptions = (chat) => {
        setOptions(chat);
    };

    const handleCancel = () => {
        setOptions(false);
        setRemoveConfirmation(false);
    };

    const handleConfirmation = (chat) => {
        setOptions(false);
        setRemoveConfirmation(chat);
    };

    const handleRemoveChats = (chat) => {
        setLoading(true)
        const data = {
            userid: state.user.userid,
            chatid: chat.userid
        };
        axios.post(configData.SECUREURL + configData.REMOVECHATS, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    const updatedChats = chats.filter((item) => item.userid !== chat.userid);
                    setChats(updatedChats);
                }
            }
        }).catch(() => {
            console.log('Couldn\'t remove chats')
        }
        ).finally(() => {
            setLoading(false)
        })
    };

    const handleClickContent = (chat, index) => {
        const updatedChats = [...chats];
        updatedChats[index] = {
            ...chat,
            read: 1,
        };
        setChats(updatedChats);
        setState(state => ({ ...state, modalopen: true, modalcontent: <Chat state={state} setState={setState} chat={chat} /> }))
    }

    return (
        <div className='chats'>
            <Title text='Chats' />

            <div className='chats_searchinputgroup'>
                <input
                    className='chats_searchinput'
                    type='text'
                    value={search}
                    placeholder='Search'
                    onChange={e => setSearch(e.target.value)}
                />
            </div>

            <div className='chats_panel'>
                {
                    chats.filter(chat => chat.username && chat.username.toLowerCase().includes(search.toLowerCase())).map((chat, index) => {
                        return (
                            <div
                                key={'chat' + index}
                                className='chats_chat'
                            >
                                <div
                                    className='chats_chatcontent'
                                    onClick={() => handleClickContent(chat, index)}
                                >
                                    <div className='chats_userprofilepicturecontainer'>
                                        <img src={chat.userpic} className='chats_userprofilepicture' alt='User Profile Pic' />
                                    </div>
                                    <div className='chats_usernamecontainer'>
                                        <div className='chats_username'>{chat.username}</div>
                                        <div className={chat.read ? 'chats_usernamechatread' : 'chats_usernamechatunread'}>{chat.lastmessage}</div>
                                    </div>
                                    <div className='chats_fromnow'>{moment(chat.timestamp * 1000).fromNow()}</div>
                                </div>
                                <div
                                    className='chats_optionscontainer'
                                    onClick={() => handleOptions(chat)}
                                >
                                    <div className='chats_option'>···</div>
                                </div>
                            </div>
                        )
                    }
                    )
                }
                {
                    !loading && chats.length == 0 ?
                        <div className='chats_nochat'>NO CHATS</div>
                        :
                        null
                }
            </div>

            {
                options && (
                    <div className='chats_modal'>
                        <div className='chats_modalcontent' ref={optionsRef}>
                            <div className='chats_modalremove' onClick={() => handleConfirmation(options)}>Remove Chat</div>
                            <div className='chats_modalcancel' onClick={handleCancel}>Cancel</div>
                        </div>
                    </div>
                )
            }

            {
                removeconfirmation && (
                    <div className='chats_modal'>
                        <div className='chats_modalcontent' ref={optionsRef}>
                            <div className='chats_modalremove' onClick={() => handleRemoveChats(removeconfirmation)}>Confirm?</div>
                            <div className='chats_modalcancel' onClick={handleCancel}>No</div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Chats;