import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import configData from './Config.json';
import moment from 'moment';
import Spinner from './Spinner.js';
import { isUnicode } from './Utils/text.js';

import './CommentsSection.css';

// Revision 16-5-2023

function CommentSection({ state, pageId }) {
    const history = useNavigate();

    const [profileuserid, setProfileUserId] = useState(null);
    const [profileopen, setProfileOpen] = useState(false);

    const [commentsdata, setCommentsdata] = useState([]);
    const [change, setChange] = useState(false);

    const [commentoptions, setCommentOptions] = useState(false);

    const [newcomment, setNewcomment] = useState('');
    const [loadingcomment, setLoadingComment] = useState(false);

    const replyinput = useRef(null);
    const [loadingreply, setLoadingreply] = useState(false);

    const [result, setResult] = useState('');

    const [commentsshowmoreloading, setCommentsshowmoreloading] = useState(false);
    const [repliesshowmoreloading, setRepliesshowmoreloading] = useState(false);

    const handleLogin = () => {
        history('/login')
    }

    const handleSignup = () => {
        history('/register')
    }

    const Modal = ({ result, onDismiss }) => {
        return (
            <div className='modal'>
                <div className='modal_content'>
                    <h2 className='modal_title'>Alert</h2>
                    <p className='modal_result'>{result}</p>
                    <button className='modal_dismissbutton' onClick={() => onDismiss(null)}>Dismiss</button>
                </div>
            </div>
        );
    };

    useEffect(() => {
        const commentsdata = {
            countryid: state.id,
            pageid: pageId,
            offset: 0
        };
        axios.post(configData.SECUREURL + configData.GETCOMMENTS, commentsdata, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            if (res.data instanceof Array) {
                setCommentsdata(res.data);
            }
        })
    }, [change]);

    const showmorecomments = (countryid, pageid, offset) => {
        setCommentsshowmoreloading(true)

        const data = {
            countryid: countryid,
            pageid: pageid,
            offset: offset
        };

        axios.post(configData.GETCOMMENTS, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            if (res.data instanceof Array) {
                setCommentsdata([...commentsdata, ...res.data]);
            }
            setCommentsshowmoreloading(false)
        }).catch(() => {
            setCommentsshowmoreloading(false)
        })
    }

    const showmorereplies = (countryid, pageid, commentid, offset) => {

        setRepliesshowmoreloading(true)

        const data = {
            countryid: countryid,
            pageid: pageid,
            commentid: commentid,
            offset: offset
        };

        axios.post(configData.SECUREURL + configData.GETREPLIES, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            if (res.data instanceof Array) {
                setCommentsdata((prevCommentsData) => {
                    const updatedCommentsData = [...prevCommentsData];
                    const commentIndex = updatedCommentsData.findIndex(
                        (comment) => comment.comId === commentid
                    );
                    if (commentIndex !== -1) {
                        updatedCommentsData[commentIndex].replies = [
                            ...(updatedCommentsData[commentIndex].replies || []),
                            ...res.data,
                        ];
                    }
                    return updatedCommentsData;
                });
            }
            setRepliesshowmoreloading(false)
        }).catch(() => {
            setRepliesshowmoreloading(false)
        })
    }

    const addComment = (countryid, pageid, userid, newcomment) => {
        if (!newcomment.length) {
            return
        }

        setLoadingComment(true)

        const data = {
            countryid: countryid,
            pageid: pageid,
            userid: userid,
            newcomment: newcomment
        };

        axios.post(configData.SECUREURL + configData.ADDCOMMENT, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setNewcomment('');
                    setCommentOptions();
                    setChange(!change);
                }
                else {
                    setResult(res.data.data)
                }
            }
            else {
                setResult('Error')
            }
            setLoadingComment(false);
        }).catch(() => {
            setLoadingComment(false)
            setResult('Error')
        })
    }

    const updateComment = (countryid, pageid, userid, commentid, commenttext) => {
        if (!commenttext.length) {
            return
        }

        setLoadingComment(true);

        const data = {
            countryid: countryid,
            pageid: pageid,
            userid: userid,
            commentid: commentid,
            newcomment: commenttext
        };
        axios.post(configData.SECUREURL + configData.UPDATECOMMENT, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setCommentOptions();
                    setCommentsdata((prevCommentsData) => {
                        return prevCommentsData.map((comment) => {
                            if (comment.comId === commentid) {
                                return {
                                    ...comment,
                                    text: commenttext,
                                };
                            }
                            return comment;
                        });
                    });
                }
                else {
                    setResult(res.data.data)
                }
            }
            else {
                setResult('Error');
            }
            setLoadingComment(false);
        }).catch(() => {
            setLoadingComment(false);
            setResult('Error');
        })
    }

    const removeComment = (countryid, pageid, userid, commentid) => {
        const data = {
            countryid: countryid,
            pageid: pageid,
            userid: userid,
            commentid: commentid,
        };

        axios.post(configData.SECUREURL + configData.REMOVECOMMENT, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setNewcomment('');
                    setCommentOptions();
                    setCommentsdata((prevCommentsData) => {
                        const updatedCommentsData = prevCommentsData.filter((comment) => comment.comId !== commentid);

                        if (updatedCommentsData.length > 0) {
                            const lastComment = updatedCommentsData[updatedCommentsData.length - 1];
                            lastComment.remaining = 0;
                        }

                        return updatedCommentsData;
                    });
                }
                else {
                    setResult(res.data.data);
                }
            }
            else {
                setResult('Error');
            }
        }).catch(() => {
            setResult('Error');
        })
    }

    const addReply = (countryid, pageid, userid, commentid, newreply) => {
        if (!newreply.length) {
            return
        }

        setLoadingreply(true)

        const data = {
            countryid: countryid,
            pageid: pageid,
            userid: userid,
            commentid: commentid,
            newreply: newreply
        };
        axios.post(configData.SECUREURL + configData.ADDREPLY, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setCommentOptions();
                    setChange(!change);
                }
                else {
                    setResult(res.data.data);
                }
            }
            else {
                setResult('Error')
            }
            setLoadingreply(false)
        }).catch(() => {
            setLoadingreply(false)
            setResult('Error')
        })
    }

    const updateReply = (countryid, pageid, userid, commentid, replyid, newreply) => {
        if (!newreply.length) {
            return
        }

        const data = {
            countryid: countryid,
            pageid: pageid,
            userid: userid,
            commentid: commentid,
            replyid: replyid,
            newreply: newreply
        };

        axios.post(configData.SECUREURL + configData.UPDATEREPLY, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setCommentOptions();
                    setCommentsdata((prevCommentsData) => {
                        return prevCommentsData.map((comment) => {
                            if (comment.comId === commentid) {
                                const updatedReplies = comment.replies.filter((reply) => reply.repId !== replyid);

                                if (updatedReplies.length > 0) {
                                    const lastReply = updatedReplies[updatedReplies.length - 1];
                                    lastReply.remaining = 0;
                                }

                                return {
                                    ...comment,
                                    replies: updatedReplies,
                                };
                            }
                            return comment;
                        });
                    });
                }
                else {
                    setResult(res.data.data);
                }
            }
            else {
                setResult('Error')
            }
        }).catch(() => {
            setResult('Error');
        })
    }

    const removeReply = (countryid, pageid, userid, commId, repId) => {
        const data = {
            countryid: countryid,
            pageid: pageid,
            userid: userid,
            commentid: commId,
            replyid: repId,
        };

        axios.post(configData.SECUREURL + configData.REMOVEREPLY, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setCommentOptions();
                    setCommentsdata((prevCommentsData) => {
                        return prevCommentsData.map((comment) => {
                            if (comment.comId === commId) {
                                const updatedReplies = comment.replies.filter((reply) => reply.repId !== repId);
                                return {
                                    ...comment,
                                    replies: updatedReplies,
                                };
                            }
                            return comment;
                        });
                    });
                }
                else {
                    setResult(res.data.data);
                }
            }
            else {
                setResult('Error');
            }
        }).catch(() => {
            setResult('No connection');
        })
    }

    const openProfile = (userid) => {
        setProfileUserId(userid);
        setProfileOpen(true);
    }

    const toggleCommentOptions = (commentid, commenttext) => {
        if (commentoptions) {
            setCommentOptions(null);
        } else {
            setCommentOptions(<CommentOptions commentid={commentid} commenttext={commenttext} />);
        }
    }

    function CommentOptions({ commentid, commenttext }) {
        return (
            <div className='commentoptions'>
                <button className='commentoptions_button' onClick={() => setCommentOptions(<EditComment commentid={commentid} commenttext={commenttext} />)}>
                    Edit
                </button>
                <button className='commentoptions_button' onClick={() => setCommentOptions(<DeleteCommentConfirmation commentid={commentid} />)}>
                    Delete
                </button>
                <button className='commentoptions_button' onClick={() => setCommentOptions()}>
                    Cancel
                </button>
            </div>
        )
    }

    function EditComment({ commentid, commenttext }) {
        const [editcomment, setEditcomment] = useState(commenttext);
        return (
            <div className='editcomment'>
                <textarea
                    className='editcomment_textarea'
                    value={editcomment}
                    onChange={e => setEditcomment(e.target.value)}
                ></textarea>
                <div className='editcomment_buttons'>
                    <button className='editcomment_buttons' onClick={() => setCommentOptions()}>Cancel</button>
                    <button className='editcomment_buttons' onClick={() => updateComment(state.id, pageId, state.user.userid, commentid, editcomment)}>Save</button>
                </div>
                <div className='editcomment_resultcontainer'>
                    <div className='editcomment_spinnercontainer'>
                        <Spinner loading={loadingcomment} />
                    </div>
                </div>
            </div>
        )
    }

    function DeleteCommentConfirmation({ commentid }) {
        return (
            <div className='deleteconfirmation'>
                <div className='deleteconfirmation_title' >
                    Confirm
                </div>
                <button className='deleteconfirmation_button' onClick={() => removeComment(state.id, pageId, state.user.userid, commentid)}>
                    Yes
                </button>
                <button className='deleteconfirmation_button' onClick={() => setCommentOptions()}>
                    Cancel
                </button>
            </div>
        )
    }

    function DeleteReplyConfirmation({ commentid, replyid }) {
        return (
            <div className='deleteconfirmation'>
                <div className='deleteconfirmation_title' >
                    Confirm
                </div>
                <button className='deleteconfirmation_button' onClick={() => removeReply(state.id, pageId, state.user.userid, commentid, replyid)}>
                    Yes
                </button>
                <button className='deleteconfirmation_button' onClick={() => setCommentOptions()}>
                    Cancel
                </button>
            </div>
        )
    }

    const toggleReplyOptions = (commentid, replyid, replytext) => {
        if (commentoptions) {
            setCommentOptions(null);
        } else {
            setCommentOptions(<ReplyOptions commentid={commentid} replyid={replyid} replytext={replytext} />);
        }
    }

    function ReplyOptions({ commentid, replyid, replytext }) {
        return (
            <div className='replyoptions'>
                <button className='replyoptions_button' onClick={() => setCommentOptions(<EditReply commentid={commentid} replyid={replyid} replytext={replytext} />)}>
                    Edit
                </button>
                <button className='replyoptions_button' onClick={() => setCommentOptions(<DeleteReplyConfirmation commentid={commentid} replyid={replyid} />)}>
                    Delete
                </button>
                <button className='replyoptions_button' onClick={() => setCommentOptions()}>
                    Cancel
                </button>
            </div>
        )
    }

    function EditReply({ commentid, replyid, replytext }) {
        const [editreply, setEditreply] = useState(replytext);
        return (
            <div className='editreply_popup'>
                <textarea
                    className='editreply_textarea'
                    value={editreply}
                    onChange={e => setEditreply(e.target.value)}
                ></textarea>
                <div className='editreply_buttons'>
                    <button className='editreply_button' onClick={() => setCommentOptions()}>Cancel</button>
                    <button className='editreply_button' onClick={() => updateReply(state.id, pageId, state.user.userid, commentid, replyid, editreply)}>Save</button>
                </div>
                <div className='editreply_resultcontainer'>
                    <div className='editreply_spinnercontainer'>
                        <Spinner loading={loadingcomment} />
                    </div>
                </div>
            </div>
        )
    }

    const toggleCommentReply = (commentid) => {
        if (commentoptions) {
            setCommentOptions(null);
        } else {
            setCommentOptions(<CommentReply commentid={commentid} />);
        }
    }

    function CommentReply({ commentid }) {
        const [newreply, setNewreply] = useState('');

        useEffect(() => {
            replyinput.current.focus();
        }, []);

        return (
            <div className='commentreply'>
                <textarea
                    className='commentreply_textarea'
                    rows="4"
                    cols="50"
                    value={newreply}
                    onChange={e => setNewreply(e.target.value)}
                    ref={replyinput}
                ></textarea>
                <div className='commentreply_buttons'>
                    <button className='commentreply_button' onClick={() => setCommentOptions()}>Cencel</button>
                    <button className='commentreply_button' onClick={() => addReply(state.id, pageId, state.user.userid, commentid, newreply)}>Reply</button>
                </div>
                <div className='commentreply_resultcontainer'>
                    <div className='commentreply_spinnercontainer'>
                        <Spinner loading={loadingreply} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='commentssection'>
            {result && <Modal result={result} onDismiss={setResult} />}
            {
                commentsdata &&
                commentsdata.map((comment, indexcomment) =>
                (
                    <div key={'comment' + indexcomment} className='commentssection_commentitem'>
                        <div className='commentssection_comment'>
                            <div className='commentssection_commentleft'>
                                <div className='commentssection_commentleftrow'>
                                    <div className='commentssection_commentleftcolleft'>
                                        <div className='commentssection_commentavatarcontainer'>
                                            <img
                                                className='commentssection_commentavatar'
                                                src={comment.avatarUrl}
                                                alt='commentssection_commentavatar'
                                                onClick={() => openProfile(comment.userId)}
                                            />
                                        </div>
                                    </div>
                                    <div className='commentssection_commentleftcolright'>
                                        <div className='commentssection_commentusernamecontainer'>
                                            <span className='commentssection_commentusername' onClick={() => openProfile(comment.userId)}>{comment.userName}</span>
                                        </div>
                                        <div className={isUnicode(comment.text) ? 'commentssection_commenttextrtl' : 'commentssection_commenttextltr'}>{comment.text}</div>
                                        {/*<div className='commentssection_debug'>Debug: off.{comment.offset} rem.{comment.remaining} comid:{comment.comId}</div>*/}
                                        <div className='commentssection_commentreplycontainer'>
                                            {
                                                state.user && state.user.userid &&
                                                <button className='commentssection_replybutton' onClick={() => toggleCommentReply(comment.comId)}>
                                                    <svg className='commentssection_replybuttonsvg' aria-hidden="true" focusable="false" data-prefix="fas" data-icon="reply" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" color="#a5a5a5">
                                                        <path fill="currentColor" d="M8.309 189.836L184.313 37.851C199.719 24.546 224 35.347 224 56.015v80.053c160.629 1.839 288 34.032 288 186.258 0 61.441-39.581 122.309-83.333 154.132-13.653 9.931-33.111-2.533-28.077-18.631 45.344-145.012-21.507-183.51-176.59-185.742V360c0 20.7-24.3 31.453-39.687 18.164l-176.004-152c-11.071-9.562-11.086-26.753 0-36.328z"></path>
                                                    </svg>
                                                    <span>Reply</span>
                                                </button>
                                            }
                                            <div className='commentssection_commentfromnow'>{comment.ts && moment(comment.ts * 1000).fromNow()}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='commentssection_commentright'>
                                {
                                    state.user && state.user.userid === comment.userId && (
                                        <div className='commentssection_commentoptionscontainer'>
                                            <button className='commentssection_commentoptionsbutton' onClick={() => toggleCommentOptions(comment.comId, comment.text)}>
                                                ...
                                            </button>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        {
                            comment.replies && comment.replies.length > 0 &&
                            <div className='commentssection_replies'>
                                {
                                    comment.replies.map((reply, indexreply) =>
                                        <div className='commentssection_reply' key={'reply' + indexreply}>
                                            <div className='commentssection_replyleft'>
                                                <div className='commentssection_replyleftrow'>
                                                    <div className='commentssection_replyleftcolleft'>
                                                        <div className='commentssection_replyavatarcontainer'>
                                                            <img
                                                                className='commentssection_replyavatar'
                                                                src={reply.avatarUrl}
                                                                alt='commentssection_replyavatar'
                                                                onClick={() => openProfile(reply.userId)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='commentssection_replyleftcolright'>
                                                        <div className='commentssection_replyusernamecontainer'>
                                                            <span className='commentssection_replyusername' onClick={() => openProfile(reply.userId)}>{reply.userName}</span>
                                                        </div>
                                                        <div className={isUnicode(reply.text) ? 'commentssection_replytextrtl' : 'commentssection_replytextltr'}>{reply.text}</div>
                                                        {/*<div className='commentssection_debug'>Debug: off.{reply.offset} rem.{reply.remaining} repid.{reply.repId}</div>*/}
                                                        <div className='commentssection_replyfromnow'>{reply.ts && moment(reply.ts * 1000).fromNow()}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='commentssection_replyright'>
                                                {
                                                    state.user && state.user.userid === reply.userId &&
                                                    <div className='commentssection_replyoptionscontainer'>
                                                        <div className='commentssection_replyoptions' onClick={() => toggleReplyOptions(comment.comId, reply.repId, reply.text)}>
                                                            ...
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    comment.replies[comment.replies.length - 1] && comment.replies[comment.replies.length - 1].remaining !== 0 &&
                                    <div className='commentssection_repliesshowmore'>
                                        <div className='commentssection_repliesshowmorespinnercontainer'>
                                            <Spinner loading={repliesshowmoreloading} />
                                        </div>
                                        <div className='commentssection_repliesshowmorebuttoncontainer'>
                                            <div className='commentssection_repliesshowmorebutton' onClick={() => showmorereplies(state.id, pageId, comment.comId, comment.replies[comment.replies.length - 1].offset)}>Show more ({comment.replies[comment.replies.length - 1].remaining})</div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                ))
            }

            {
                commentsdata[commentsdata.length - 1] && commentsdata[commentsdata.length - 1].remaining !== 0 &&
                <div className='commentssection_commentsshowmore'>
                    <div className='commentssection_commentsshowmorespinnercontainer'>
                        <Spinner loading={commentsshowmoreloading} />
                    </div>
                    <div className='commentssection_commentsshowmorebuttoncontainer'>
                        <div className='commentssection_commentsshowmorebutton' onClick={() => showmorecomments(state.id, pageId, commentsdata[commentsdata.length - 1].offset)}>Show more ({commentsdata[commentsdata.length - 1].remaining})</div>
                    </div>
                </div>
            }

            {
                commentoptions
            }

            {
                state.user && state.user.userid ?
                    (
                        <div className='commentssection_loggedincontainer'>
                            {
                                state.user.userprofilepicture &&
                                <div className='commentssection_loggedincolumnsmall'>
                                    <div className='commentssection_loggedinavatarcontainer'>
                                        <img className='commentssection_loggedinavatar' src={state.user.userprofilepicture} alt="userIcon" />
                                    </div>
                                </div>
                            }
                            <div className='commentssection_loggedincolumnbig'>
                                <input
                                    className='commentssection_loggedininput'
                                    type="text"
                                    component="input"
                                    value={newcomment}
                                    onChange={e => setNewcomment(e.target.value)}
                                    dir="auto"
                                    onKeyDown={event => {
                                        if (event.key === 'Enter') {
                                            addComment(state.id, pageId, state.user.userid, newcomment);
                                        }
                                    }
                                    }
                                    disabled={loadingcomment}
                                />
                            </div>
                            <div className='commentssection_loggedincolumnsmall'>
                                <button className={newcomment.length > 0 ? 'commentssection_postbutton commentssection_postbuttonhighlight' : 'commentssection_postbutton'} type="button" onClick={() => addComment(state.id, pageId, state.user.userid, newcomment)}>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" t="1569683742680" viewBox="0 0 1024 1024" version="1.1" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><defs></defs><path d="M931.4 498.9L94.9 79.5c-3.4-1.7-7.3-2.1-11-1.2-8.5 2.1-13.8 10.7-11.7 19.3l86.2 352.2c1.3 5.3 5.2 9.6 10.4 11.3l147.7 50.7-147.6 50.7c-5.2 1.8-9.1 6-10.3 11.3L72.2 926.5c-0.9 3.7-0.5 7.6 1.2 10.9 3.9 7.9 13.5 11.1 21.5 7.2l836.5-417c3.1-1.5 5.6-4.1 7.2-7.1 3.9-8 0.7-17.6-7.2-21.6zM170.8 826.3l50.3-205.6 295.2-101.3c2.3-0.8 4.2-2.6 5-5 1.4-4.2-0.8-8.7-5-10.2L221.1 403 171 198.2l628 314.9-628.2 313.2z"></path></svg>
                                </button>
                            </div>
                        </div>
                    ) :
                    (
                        <div className='commentssection_loggedoutcontainer'>
                            <div className='commentssection_loggedoutcolumn'>
                                <div className='commentssection_loggedouttext'>Login to comment</div>
                            </div>
                            <div className='commentssection_loggedoutcolumnbig'>
                                <button className='commentssection_loginbutton' name="login" onClick={handleLogin}>Login</button>
                            </div>
                            <div className='commentssection_loggedoutcolumnsmall'>
                                <button className='commentssection_signupbutton' name="signup" onClick={handleSignup}>Signup</button>
                            </div>
                        </div>
                    )
            }
        </div>
    )
}

export default CommentSection;