import React from 'react';
import { useNavigate } from 'react-router-dom';

import Districts from './Districts.js';
import Communities from './Communities.js';
import AddListing from './AddListing.js';
import Likes from './Likes.js';
import MyListings from './MyListings.js';
import SoukAkaratAI from './SoukAkaratAI.js';
import Admin from './Admin/Admin.js';

import bottomnavigator_addlisting from './Assets/bottomnavigator/bottomnavigator_addlisting.png';
import bottomnavigator_likes from './Assets/bottomnavigator/bottomnavigator_likes.png';
import bottomnavigator_mylistings from './Assets/bottomnavigator/bottomnavigator_mylistings.png';

import './BottomNavigator.css';

//revision 20240418

function BottomNavigator({ state, setState }) {
  const history = useNavigate();

  const handleDistricts = () => {
    setState(state => ({ ...state, modalopen: true, modalcontent: <Districts state={state} setState={setState} /> }))
  }

  const handleCommunities = () => {
    setState(state => ({ ...state, modalopen: true, modalcontent: <Communities state={state} setState={setState} /> }))
  }


  const handleAdmin = () => {
    setState(state => ({ ...state, modalopen: true, modalcontent: <Admin state={state} setState={setState} /> }))
  }

  const handleAddListing = () => {
    if (state.user.userid != '') {
      setState(state => ({ ...state, modalopen: true, modalcontent: <AddListing state={state} setState={setState} /> }))
    }
    else {
      history('/authscreen');
    }
  }

  const handleLikes = () => {
    setState(state => ({ ...state, modalopen: true, modalcontent: <Likes state={state} setState={setState} /> }))
  }

  const handleMyListings = () => {
    setState(state => ({ ...state, modalopen: true, modalcontent: <MyListings state={state} setState={setState} /> }))
  }

  const handleAI = () => {
    setState(state => ({ ...state, modalopen: true, modalcontent: <SoukAkaratAI state={state} setState={setState} /> }))
  }

  return (
    state.user.userid ?
      <div className="bottomnavigator">
        <div className="bottomnavigator_section">
          <button className="bottomnavigator_button" onClick={handleDistricts}>Districts</button>
        </div>
        <div className="bottomnavigator_section">
          <button className="bottomnavigator_button" onClick={handleCommunities}>Communities</button>
        </div>
        <div className="bottomnavigator_section">
          {state.user.usertype == 2 && <button className="bottomnavigator_button" onClick={handleAdmin}>Admin</button>}
        </div>
        <div className="bottomnavigator_section">
          <button className="bottomnavigator_button" onClick={handleAddListing}>
            <img className="bottomnavigator_icon" src={bottomnavigator_addlisting} alt="Add Listing" />
          </button>
        </div>
        <div className="bottomnavigator_section">
          <button className="bottomnavigator_button" onClick={handleLikes}>
            <img className="bottomnavigator_icon" src={bottomnavigator_likes} alt="Likes" />
          </button>
        </div>
        <div className="bottomnavigator_section">
          <button className="bottomnavigator_button" onClick={handleMyListings}>
            <img className="bottomnavigator_icon" src={bottomnavigator_mylistings} alt="My Listings" />
          </button>
        </div>
        <div className="bottomnavigator_section">
          <button className="bottomnavigator_button" onClick={handleAI}>Soukakarat AI</button>
        </div>
      </div>
      :
      <div className="bottomnavigator">
        <div className="bottomnavigator_section">
          <button className="bottomnavigator_button" onClick={handleDistricts}>Districts</button>
        </div>
        <div className="bottomnavigator_section">
          <button className="bottomnavigator_button" onClick={handleCommunities}>Communities</button>
        </div>
        <div className="bottomnavigator_section">
          <button className="bottomnavigator_button" onClick={handleAddListing}>
            <img className="bottomnavigator_icon" src={bottomnavigator_addlisting} alt="Add Listing" />
          </button>
        </div>
        <div className="bottomnavigator_section"></div>
        <div className="bottomnavigator_section">
          <button className="bottomnavigator_button" onClick={handleAI}>SOUKAKARAT AI</button>
        </div>
      </div>
  );
}

export default BottomNavigator;