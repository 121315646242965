import React from 'react';
import Title from './Title';

import './Districts.css';

//revision 20240417

function Districts({ state, setState }) {

  const handleDistrict = (district) => {
    console.log(state)
    setState(state => ({ ...state, selecteddistrict: district.id }));
    setState(state => ({ ...state, selectedcommunity: 0 }));
    let communities = district.communities;
    setState(state => ({ ...state, communities: communities }));
    setState(state => ({ ...state, modalopen: false, modalcontent: null }));
  }

  return (
    <div className="districts">
      <Title text='Districts' />
      {
        state.menu.map((district, index) => {
          const totalCommunities = district.communities.reduce((total, community) => total + community.no, 0);

          return (
            <div key={'district' + index}
              className={state.selecteddistrict == district.id ? 'districts_district districts_districtselected' : 'districts_district'}
              onClick={() => handleDistrict(district)}
            >
              {district.name} ({totalCommunities})
            </div>
          )
        })
      }
    </div>
  );
}

export default Districts;