import React, { useEffect, useState } from 'react';
import Districts from './Districts.js';
import Communities from './Communities.js';

import './Navigator.css';

//Revision 20240418

function Navigator({ state, setState }) {

    const [selectedCountryName, setSelectedCountryName] = useState('');
    const [selectedDistrictName, setSelectedDistrictName] = useState('');
    const [selectedCommunityName, setSelectedCommunityName] = useState('');
    const [selectedAgreementName, setSelectedAgreementName] = useState('');
    const [selectedTypeName, setSelectedTypeName] = useState('');

    useEffect(() => {
        const countryname = state.countries.find(country => country.id == state.selectedcountry)?.name;
        setSelectedCountryName(countryname);
    }, [state.selectedcountry]);

    useEffect(() => {
        const districtname = state.menu.find(district => district.id == state.selecteddistrict)?.name;
        setSelectedDistrictName(districtname);
    }, [state.menu, state.selecteddistrict]);

    useEffect(() => {
        if (state.selecteddistrict !== null && state.selectedcommunity !== null) {
            const district = state.menu.find(district => district.id === state.selecteddistrict);
            const community = district?.communities.find(community => community.id === state.selectedcommunity);
            setSelectedCommunityName(community ? community.name : '');
        }
    }, [state.selecteddistrict, state.selectedcommunity]);

    useEffect(() => {
        const agreementname = state.agreements.find(agreement => agreement.id == state.selectedagreement)?.agreement || 'All';
        setSelectedAgreementName(agreementname)
    }, [state.selectedagreement]);

    useEffect(() => {
        const typename = state.types.find(type => type.id == state.selectedtype)?.type || 'All';
        setSelectedTypeName(typename)
    }, [state.selectedtype]);

    const handleDistricts = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Districts state={state} setState={setState} /> }))
    }

    const handleCommunities = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Communities state={state} setState={setState} /> }))
    }

    return (
        <div className='navigator' >
            <span className='navigator_link'>{selectedCountryName}</span>
            <span className='navigator_seperator'> &gt; </span>
            <span className='navigator_link' onClick={() => handleDistricts()}>{selectedDistrictName}</span>
            <span className='navigator_seperator'> &gt; </span>
            <span className='navigator_link' onClick={() => handleCommunities()}>
                {
                    selectedCommunityName !== '' ?
                        selectedCommunityName
                        :
                        'No community selected'
                }
            </span>
            <span className='navigator_seperator'> | </span>
            <span className='navigator_link'>Agreement: {selectedAgreementName}</span>
            <span className='navigator_seperator'> </span>
            <span className='navigator_link'>Type: {selectedTypeName}</span>
        </div>
    )
}

export default Navigator;