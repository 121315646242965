import React, { useState, useEffect } from 'react';
import Title from './Title';
import axios from 'axios';
import configData from './Config.json';
import Spinner from './Spinner.js';

import './Likes.css';

function Likes({ state, setState }) {
  const [likes, setLikes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const data = {
      userid: state.user.userid
    };

    axios.post(configData.SECUREURL + configData.GETMYLIKES, data, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "userid": state.user.userid,
        "usertoken": state.user.usertoken
      },
    }).then(res => {
      console.log(res.data)
      if (res.data instanceof Array) {
        setLikes([])
      }
    }).catch(() => {
      console.error('Error fetching profile')
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  return (
    <div className="likes">
      <Title text='Likes' />
      <Spinner loading={loading} size={30} />

      {
        likes.map((like, index) =>
          <div>{like.listingid}</div>
        )
      }
    </div>
  );
}

export default Likes;