import React, { useState, useEffect } from 'react';
import Title from './Title';
import axios from 'axios';
import moment from 'moment';
import configData from './Config.json';

import './Notifications.css';

//revision 20240417

function Notifications({ state, setState }) {
    const [notifications, setNotifications] = useState([]);
    const [notificationscount, setNotificationscount] = useState(0);

    useEffect(() => {
        setNotifications(state.notifications)
        setNotificationscount(state.notificationscount)
    }, []);

    useEffect(() => {
        const data = {
            userid: state.user.userid
        };
        axios.post(configData.SECUREURL + configData.CLEARNOTIFICATIONSCOUNT, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setNotificationscount(0)
                    setState(state => ({ ...state, notificationscount: 0 }))
                }
            }
        }).catch(() => {
            console.error("Couldn/'t clear notifications");
        })
    }, []);

    return (
        <div className='notifications'>
            <Title text='Notifications' />
            {
                notifications.map((not, index) => {
                    const isHighlighted = notificationscount > 0 && index < notificationscount;
                    return (
                        <div key={'not' + index} className={isHighlighted ? 'notifications_notification notifications_highlighted' : 'notifications_notification'} >
                            <div className='notifications_notificationtext'>{not.NotificationMessage}</div>
                            <div className='notifications_notificationago'>{moment(not.Ts * 1000).fromNow()}</div>
                        </div>
                    )
                })
            }
            {
                !notifications || notifications.length === 0 &&
                <div className='notifications_notification'>No notifications</div>
            }
        </div>
    )
}

export default Notifications;