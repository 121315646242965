import React, { useEffect, useState } from 'react';
import axios from 'axios';
import configData from './Config.json';
import { useNavigate } from 'react-router-dom';
import Title from './Title';

import Spinner from './Spinner.js';
import { isUnicode } from './Utils/text.js';

import placeholderimage from './Assets/profile/default.jpg';

import './Settings.css';

function Settings({ state, setState }) {

    const [openupload, setOpenupload] = useState(false);

    const [imagetoupload, setImageToUpload] = useState(null);
    const [preview, setPreview] = useState();

    const [userid, setUserid] = useState('');
    const [username, setUsername] = useState('');
    const [usernamedirectionrtl, setUsernamedirectionrtl] = useState(false);

    const [email, setEmail] = useState('');

    const [biography, setBiography] = useState('');
    const [biographydirectionrtl, setBiographydirectionrtl] = useState(false);

    const [showModal, setShowModal] = useState(false);

    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState();

    const history = useNavigate();

    useEffect(() => {
        setUserid(state.user.userid);
        setPreview(state.user.userprofilepicture);
        setEmail(state.user.useremail);
        setUsername(state.user.username);
        setBiography(state.user.userbiography);
        console.log(state)
    }, [state.user])

    function handleFileChange(event) {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImageToUpload(file);
            setPreview(reader.result);
        };
    }

    useEffect(() => {
        if (isUnicode(username)) {
            setUsernamedirectionrtl(true);
        } else {
            setUsernamedirectionrtl(false);
        }
    }, [username]);

    useEffect(() => {
        if (isUnicode(biography)) {
            setBiographydirectionrtl(true);
        } else {
            setBiographydirectionrtl(false);
        }
    }, [biography]);

    const handleSubmit = () => {
        console.log('Trying to update profile')
        setLoading(true);

        if (username == '') {
            setResult('Please provide username')
            setLoading(false);
            return
        }

        const updatemyprofileformdata = new FormData();
        updatemyprofileformdata.append('userid', userid);
        updatemyprofileformdata.append('username', username);
        updatemyprofileformdata.append('biography', biography);
        updatemyprofileformdata.append('file', imagetoupload);

        axios.post(configData.SECUREURL + configData.UPDATEMYPROFILE, updatemyprofileformdata, {
            headers: {
                "Content-Type": "multipart/form-data",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Update my profile data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setResult('Success');
                    setState(state => ({ ...state, user: { ...state.user, userbiography: biography } }))
                    setState(state => ({ ...state, user: { ...state.user, username: username } }))
                }
                if (res.data.profilepicture) {
                    setState(state => ({ ...state, user: { ...state.user, userprofilepicture: res.data.newprofilepic } }))
                    setPreview(res.data.newImageName);
                }
            }
            else {
                setResult('Couldn\'t update profile');
            }
        }).catch((error) => {
            setResult(error.response)
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleChangePassword = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null }));
        history('/authscreen?changepassword=true&uid=' + state.user.userid + '&t=' + state.user.usertoken)
    }

    const handleRemove = () => {
        setShowModal(true);
    };

    const handleConfirmRemove = () => {
        console.log('Remove my profile')
        setLoading(true);

        const data = {
            userid: state.user.userid
        };

        axios.post(configData.SECUREURL + configData.REMOVEMYPROFILE, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Received remove my profile data')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setShowModal(false);
                    setResult('Successfully removed account')
                }
                else {
                    setResult('Could not delete account');
                    setShowModal(false);
                }
            }
            else {
                setResult('Could not delete account');
                setShowModal(false);
            }
        }).catch(() => {
            setResult('Network error');
        }).finally(() => {
            setLoading(false);
        })
    };

    const handleCancelRemove = () => {
        setShowModal(false);
    };

    const handleRemoveImage = () => {
        setPreview(null);
        setImageToUpload(null);

        console.log('Trying to remove my profile picture')

        const data = {
            userid: state.user.userid
        };

        axios.post(configData.SECUREURL + configData.REMOVEMYPROFILEPICTURE, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Received remove my profile picture data')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setState(state => ({ ...state, user: { ...state.user, userprofilepicture: '' } }))
                }
                else {

                }
            }
            else {

            }
        }).catch(() => {
            setResult('Could not remove profile picture');
        })
    };

    return (
        <div className='settings'>

            <Title text='Settings' />

            <div className='settings_row'>
                <div className='settings_rowsection'>
                    <div className='settings_removemyprofilepicturecontainer'>
                        <div className='settings_removemyprofilepicturebutton' onClick={handleRemoveImage}>Remove Picture</div>
                    </div>
                    {
                        preview ?
                            <>
                                <img className='settings_profilepicture' src={preview} alt="Preview Profile Picture" />
                            </>
                            :
                            <img className='settings_profilepicture' src={placeholderimage} alt="Profile Picture" />
                    }
                </div>
            </div>

            <div className='settings_row'>
                <div className='settings_rowsection'>
                    <button className='settings_uploadbutton' onClick={() => setOpenupload(!openupload)}>UPLOAD PROFILE PICTURE</button>
                </div>
            </div>

            {
                openupload &&
                <div className='settings_row'>
                    <div className='settings_rowsection'>
                        <input className='settings_uploadbuttoninput' type='file' onChange={handleFileChange} />
                    </div>
                </div>
            }

            <div className='settings_row'>
                <div className='settings_rowsection'>
                    <label className='settings_label' htmlFor='username'>Username</label>
                </div>
            </div>

            <div className='settings_row'>
                <div className='settings_rowsection'>
                    <input
                        className={usernamedirectionrtl ? 'settings_input settings_inputrtl' : 'settings_input settings_inputltr'}
                        id='username'
                        placeholder='Username'
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        autoComplete="off"
                    />
                </div>
            </div>

            <div className='settings_row'>
                <div className='settings_rowsection'>
                    <label className='settings_label' htmlFor='emaillAddress'>Email</label>
                </div>
            </div>

            <div className='settings_row'>
                <div className='settings_rowsection'>
                    <input
                        className='settings_input'
                        disabled
                        id='emaillAddress'
                        placeholder='Email'
                        value={email}
                    />
                </div>
            </div>

            <div className='settings_row'>
                <div className='settings_rowsection'>
                    <label className='settings_label' htmlFor='biography'>Biography</label>
                </div>
            </div>

            <div className='settings_row'>
                <div className='settings_rowsection'>
                    <textarea
                        className={biographydirectionrtl ? 'settings_textarea settings_textareartl' : 'settings_textarea settings_textarealtr'}
                        id='biography'
                        placeholder='Biography'
                        value={biography}
                        onChange={e => setBiography(e.target.value)}
                    />
                </div>
            </div>

            <div className='settings_row'>
                <div className='settings_rowsection'>
                    <div className='settings_result'>
                        {result && result}
                    </div>
                </div>
            </div>

            <div className='settings_row'>
                <div className='settings_rowsection'>
                    <Spinner loading={loading} size={50} />
                </div>
            </div>

            <div className='settings_row'>
                <div className='settings_rowsection'>
                    <button className='settings_savebutton' onClick={handleSubmit}>Save Settings</button>
                    <button className='settings_changepasswordbutton' onClick={handleChangePassword}>Change Password</button>
                </div>
            </div>

            <div className='settings_row'>
                <div className='settings_rowsection'>
                    <div className='settings_removeprofilecontainer'>
                        <span className='settings_removeprofilebutton' onClick={handleRemove}>Remove profile</span>
                    </div>
                    {
                        showModal && (
                            <div className='settings_removeprofilemodal'>
                                <h2 className='settings_removeprofilemodaltitle'>Are you sure ?</h2>
                                <div>
                                    <button className='settings_removeprofilemodalbutton' onClick={handleConfirmRemove}>Yes</button>
                                    <button className='settings_removeprofilemodalbutton' onClick={handleCancelRemove}>No</button>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Settings;