import React, { useState, useEffect } from 'react';
import axios from 'axios';
import configData from './Config.json';
import Title from './Title';
import Chat from './Chat';

import placeholderimage from './Assets/profile/default.jpg';

import './Profile.css';

//revision 20240418

function Profile({ state, setState, listerid }) {

    const [profileuser, setProfileUser] = useState({ username: '', userbiography: '', userprofilepicture: '' });

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(false);

        const data = {
            profileid: listerid
        };

        axios.post(configData.SECUREURL + configData.GETPROFILE, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            if (res.data instanceof Object) {
                setProfileUser(res.data)
                setLoaded(true)
            }
        }).catch(() => {
            console.error('Error fetching profile')
        })
    }, [])

    const openChat = (user) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Chat state={state} setState={setState} user={user} /> }))
    }

    return (
        <div className='profile'>
            <Title text='Profile Details' />
            <div className='profile_username'>{profileuser.username}</div>
            {
                loaded ?
                    <img className='profile_userprofilepicture' src={profileuser.userprofilepicture} alt='Profile Picture' />
                    :
                    <img className='profile_userprofilepicture' src={placeholderimage} alt='Profile Picture' />
            }
            <div className='profile_userbiocontainer'>
                <div className='profile_userbio'>
                    {profileuser.userbiography}
                </div>
            </div>
            {
                state.user.userid && (state.user.userid !== listerid) &&
                <div className='profile_chatbuttoncontainer'>
                    <div
                        className='profile_chatbutton'
                        onClick={() => openChat(profileuser)}
                    >
                        Chat
                    </div>
                </div>
            }
        </div>
    )
}

export default Profile;