import React from 'react';
import Title from '../Title.js';
import AgreementsEditor from './AgreementsEditor.js';
import Announcements from './Announcements.js';
import CountryEditor from './CountryEditor.js';
import Maintenance from './Maintenance.js';
import ListingsEditor from './ListingsEditor.js';
import MenuEditor from './MenuEditor.js';
import MetatagsEditor from './MetatagsEditor.js';
import SmartSearchEditor from './SmartSearchEditor.js';
import SocialMediaEditor from './SocialMediaEditor.js';
import TypesEditor from './TypesEditor.js';
import Users from './Users.js';

import './Admin.css';

//revision 20240422

function Admin({ state, setState }) {
    const handleAgreementsEditor = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AgreementsEditor state={state} setState={setState} /> }))
    }
    const handleAnnouncements = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Announcements state={state} setState={setState} /> }))
    }
    const handleCountryEditor = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <CountryEditor state={state} setState={setState} /> }))
    }
    const handleMaintenance = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Maintenance state={state} setState={setState} /> }))
    }
    const handleListingsEditor = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ListingsEditor state={state} setState={setState} /> }))
    }
    const handleMenuEditor = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <MenuEditor state={state} setState={setState} /> }))
    }
    const handleMetatagsEditor = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <MetatagsEditor state={state} setState={setState} /> }))
    }
    const handleSmartSearchEditor = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <SmartSearchEditor state={state} setState={setState} /> }))
    }
    const handleSocialMediaEditor = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <SocialMediaEditor state={state} setState={setState} /> }))
    }
    const handleTypesEditor = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <TypesEditor state={state} setState={setState} /> }))
    }
    const handleUsers = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Users state={state} setState={setState} /> }))
    }

    return (
        <div className='admin'>
            <Title text='Admin' />

            <div className="admin_item" onClick={handleAgreementsEditor}>Agreements Editor</div>
            <div className="admin_item" onClick={handleAnnouncements}>Announcements</div>
            <div className="admin_item" onClick={handleCountryEditor}>Country Editor</div>
            <div className="admin_item" onClick={handleMaintenance}>Maintenance</div>
            <div className="admin_item" onClick={handleListingsEditor}>Listings Editor</div>
            <div className="admin_item" onClick={handleMenuEditor}>Menu Editor</div>
            <div className="admin_item" onClick={handleMetatagsEditor}>Metatags Editor</div>
            <div className="admin_item" onClick={handleSmartSearchEditor}>Smart Search Editor</div>
            <div className="admin_item" onClick={handleSocialMediaEditor}>Social Media Editor</div>
            <div className="admin_item" onClick={handleTypesEditor}>Types Editor</div>
            <div className="admin_item" onClick={handleUsers}>Users</div>
        </div>
    );
};

export default Admin;