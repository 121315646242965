export function isUnicode(str) {
    const arabicRegex = /[\u0600-\u06FF]/;
    return arabicRegex.test(str);
}

export function serialize(obj) {
    const serializedObj = {};
    for (const [key, value] of Object.entries(obj)) {
        if (value !== '') {
            serializedObj[key] = value;
        }
    }
    return serializedObj;
}

export function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
}

export function findLocation(description, locations) {
    const results = [];

    const normalizedDescription = normalizeText(description);

    locations.forEach(location => {
        if (location.keyword) {
            const keywordEnglish = normalizeText(location.keyword);
            if (normalizedDescription.includes(keywordEnglish)) {
                results.push(location);
                return;
            }
        }
        if (location.keywordarabic) {
            const keywordArabic = normalizeText(location.keywordarabic);
            if (normalizedDescription.includes(keywordArabic)) {
                results.push(location);
            }
        }
    });

    return results;
}

function normalizeText(text) {
    if (!text) return '';

    const arabicCharMap = {
        'ا': /[إٱأءآ]/g,
        'ه': /[ة]/g,
    };

    for (let char in arabicCharMap) {
        text = text.replace(arabicCharMap[char], char);
    }

    const englishCharMap = {
        'a': /[A]/g,
        'b': /[B]/g,
        'c': /[C]/g,
        'd': /[D]/g,
        'e': /[E]/g,
        'f': /[F]/g,
        'g': /[G]/g,
        'h': /[H]/g,
        'i': /[I]/g,
        'j': /[J]/g,
        'k': /[K]/g,
        'l': /[L]/g,
        'm': /[M]/g,
        'n': /[N]/g,
        'o': /[O]/g,
        'p': /[P]/g,
        'q': /[Q]/g,
        'r': /[R]/g,
        's': /[S]/g,
        't': /[T]/g,
        'u': /[U]/g,
        'v': /[V]/g,
        'w': /[W]/g,
        'x': /[X]/g,
        'y': /[Y]/g,
        'z': /[Z]/g,
    };

    for (let char in englishCharMap) {
        text = text.replace(englishCharMap[char], char);
    }

    text = text.toLowerCase();

    return text;
}

export function suggestLocation(input, locations) {
    const suggestions = [];

    const normalizedInput = normalizeText(input);

    locations.forEach(location => {
        if (location.keyword) {
            const keywordEnglish = normalizeText(location.keyword);
            if (keywordEnglish.includes(normalizedInput)) {
                suggestions.push(location);
            }
        }
        if (location.keywordarabic) {
            const keywordArabic = normalizeText(location.keywordarabic);
            if (keywordArabic.includes(normalizedInput)) {
                suggestions.push(location);
            }
        }
    });

    return suggestions;
}