import React, { useEffect, useState } from 'react';
import axios from 'axios';
import configData from './Config.json';
import Title from './Title.js';

import { serialize, isUnicode, findLocation, suggestLocation } from './Utils/text.js';
import Spinner from './Spinner.js';
import FileUploader from './FileUploader.js';
import SpecsComponent from './SpecsComponent.js';

import addlisting_clearbutton from './Assets/addlisting/addlisting_clearbutton.png';

import './AddListing.css';

//Revision 6-5-2023

function AddListing({ state, setState }) {
    const [description, setDescription] = useState('');
    const [descriptiondirectionrtl, setDescriptiondirectionrtl] = useState(false);
    const [advertisingno, setAdvertisingno] = useState('');

    const [smartsearch, setSmartsearch] = useState('');
    const [smartsearchdirectionrtl, setSmartsearchdirectionrtl] = useState(false);
    const [smartsearchloading, setSmartsearchloading] = useState(false);
    const [smartsearchresult, setSmartsearchresult] = useState([]);
    const [smartsearchtimeout, setSmartsearchtimeout] = useState(null);

    const [parserlocations, setParserlocations] = useState([]);

    const [fullmenu, setFullmenu] = useState([]);
    const [district, setDistrict] = useState('');
    const [communities, setCommunities] = useState([]);
    const [community, setCommunity] = useState('');
    const [type, setType] = useState('');
    const [agreement, setAgreement] = useState('');

    const [media, setMedia] = useState([]);

    const [addlistingresult, setAddlistingresult] = useState(null);
    const [addlistingloading, setAddlistingloading] = useState(false);

    const [moredetails, setMoreDetails] = useState(false);
    const [specs, setSpecs] = useState({});

    const [parser, setParser] = useState({});
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        const selectedCountry = state.countries.find(country => country.id == state.selectedcountry);

        if (!selectedCountry) {
            console.error('Selected country not found');
            return;
        }

        const localStorageKey = selectedCountry.dir + 'keywords';
        const storedCountryDirKeywords = JSON.parse(localStorage.getItem(localStorageKey));

        if (storedCountryDirKeywords && (Date.now() - storedCountryDirKeywords.timestamp < 10 * 24 * 60 * 60 * 1000)) {
            setLocations(storedCountryDirKeywords.keywords)
        }
        else {
            const data = {
                countryid: state.selectedcountry,
            };

            axios.post(configData.SECUREURL + configData.GETLOCATIONS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then(res => {
                if (res.data instanceof Object) {
                    const newCountryDirKeywords = {
                        timestamp: Date.now(),
                        keywords: res.data.data
                    };
                    localStorage.setItem(selectedCountry.dir + 'keywords', JSON.stringify(newCountryDirKeywords));
                    setLocations(res.data.data)
                }
            }).catch(() => {
                console.error('Error fetching locations')
            })
        }
    }, []);

    useEffect(() => {
        const selectedCountry = state.countries.find(country => country.id == state.selectedcountry);

        if (!selectedCountry) {
            console.error('Selected country not found');
            return;
        }

        const localStorageKey = selectedCountry.dir + 'fullmenu';
        const storedCountryDirFullMenu = JSON.parse(localStorage.getItem(localStorageKey));

        if (storedCountryDirFullMenu && (Date.now() - storedCountryDirFullMenu.timestamp < 10 * 24 * 60 * 60 * 1000)) {
            setFullmenu(storedCountryDirFullMenu.fullmenu)
        }
        else {
            const data = {
                countryid: state.selectedcountry,
            };

            axios.post(configData.SECUREURL + configData.GETFULLMENU, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then(res => {
                if (res.data instanceof Array) {
                    const newCountryDirFullMenu = {
                        timestamp: Date.now(),
                        fullmenu: res.data
                    };
                    localStorage.setItem(selectedCountry.dir + 'fullmenu', JSON.stringify(newCountryDirFullMenu));
                    setFullmenu(res.data)
                }
            }).catch(() => {
                console.error('Error fetching fullmenu')
            })
        }
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (description !== '') {
                const data = {
                    countryid: state.selectedcountry,
                    text: description
                };

                axios.post(configData.SECUREURL + configData.GETBOTREPLY, data, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                        "userid": state.user.userid,
                        "usertoken": state.user.usertoken
                    }
                }).then(res => {
                    if (res.data instanceof Object) {
                        setParser(res.data);
                    }
                }).catch(() => { })
            }
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, [description]);

    useEffect(() => {
        if (parser && parser.room && parser.room.data && parser.room.data.length > 0) {
            let foundtype = state.types.filter((typ) => typ.type == parser.room.value)
            setType(foundtype[0].id)
        }
        if (parser && parser.apartment && parser.apartment.data && parser.apartment.data.length > 0) {
            let foundtype = state.types.filter((typ) => typ.type == parser.apartment.value)
            setType(foundtype[0].id)
        }
        if (parser && parser.building && parser.building.data && parser.building.data.length > 0) {
            let foundtype = state.types.filter((typ) => typ.type == parser.building.value)
            setType(foundtype[0].id)
        }
        if (parser && parser.duplex && parser.duplex.data && parser.duplex.data.length > 0) {
            let foundtype = state.types.filter((typ) => typ.type == parser.duplex.value)
            setType(foundtype[0].id)
        }
        if (parser && parser.land && parser.land.data && parser.land.data.length > 0) {
            let foundtype = state.types.filter((typ) => typ.type == parser.land.value)
            setType(foundtype[0].id)
        }
        if (parser && parser.office && parser.office.data && parser.office.data.length > 0) {
            let foundtype = state.types.filter((typ) => typ.type == parser.office.value)
            setType(foundtype[0].id)
        }
        if (parser && parser.studio && parser.studio.data && parser.studio.data.length > 0) {
            let foundtype = state.types.filter((typ) => typ.type == parser.studio.value)
            setType(foundtype[0].id)
        }
        if (parser && parser.villa && parser.villa.data && parser.villa.data.length > 0) {
            let foundtype = state.types.filter((typ) => typ.type == parser.villa.value)
            setType(foundtype[0].id)
        }
        if (parser && parser.warehouse && parser.warehouse.data && parser.warehouse.data.length > 0) {
            let foundtype = state.types.filter((typ) => typ.type == parser.warehouse.value)
            setType(foundtype[0].id)
        }
        if (parser && parser.offplan && parser.offplan.data && parser.offplan.data.length > 0) {
            let foundtype = state.types.filter((typ) => typ.type == parser.offplan.value)
            setType(foundtype[0].id)
        }
        if (parser && parser.store && parser.store.data && parser.store.data.length > 0) {
            let foundtype = state.types.filter((typ) => typ.type == parser.store.value)
            setType(foundtype[0].id)
        }
        if (parser && parser.commercial && parser.commercial.data && parser.commercial.data.length > 0) {
            let foundtype = state.types.filter((typ) => typ.type == parser.commercial.value)
            setType(foundtype[0].id)
        }

        if (parser && parser.sale && parser.sale.data && parser.sale.data.length > 0) {
            let foundagreement = state.agreements.filter((agr) => agr.agreement == parser.sale.value)
            setAgreement(foundagreement[0].id)
        }
        if (parser && parser.rent && parser.rent.data && parser.rent.data.length > 0) {
            let foundagreement = state.agreements.filter((agr) => agr.agreement == parser.rent.value)
            setAgreement(foundagreement[0].id)
        }

        let locationsfound = findLocation(description, locations);
        setParserlocations(locationsfound)

        if (locationsfound.length == 1) {
            var selecteddistrict = state.menu.filter((item) => item.id === locationsfound[0].district)
            setCommunities(selecteddistrict[0].communities)
            setDistrict(locationsfound[0].district)
            setCommunity(locationsfound[0].community)
            setSmartsearch('')
            setSmartsearchresult([])
        }

        if (locationsfound.length > 1) {
            console.log(locationsfound)
            console.log('More than 1 location found...')
            console.log('Checking if all same')
            const firstLocation = locationsfound[0];
            var selecteddistrict = state.menu.find((item) => item.id === firstLocation.district)

            const sameDistrict = locationsfound.every(loc => loc.district === firstLocation.district);
            const sameCommunity = locationsfound.every(loc => loc.community === firstLocation.community);

            if (sameDistrict && sameCommunity) {
                console.log('All locations are same, switching to first one')
                setCommunities(selecteddistrict?.communities)
                setDistrict(firstLocation.district)
                setCommunity(firstLocation.community)
                setSmartsearch('')
                setSmartsearchresult([])
            }
        }
    }, [parser]);

    function missinginfo(parser) {
        if (parser.spam && parser.spam.data && parser.spam.data.length > 0) {
            return { code: 1, data: "Spam detected" };
        }

        if (parser.hashtags && parser.hashtags.data && parser.hashtags.data.length > 0) {
            return { code: 1, data: "Hashtags not accepted" };
        }

        if (parser.unwanted && parser.unwanted.sentences && parser.unwanted.sentences.length > 0) {
            return { code: 1, data: "Found unwanted data." };
        }

        if (parser.contactnumber && parser.contactnumber.data && parser.contactnumber.data.length == 0) {
            if (parser.link && parser.link.data && parser.link.data.length == 0) {
                return { code: 1, data: "Missing contact number or link" };
            }
        }

        if (parser.sale && parser.sale.data && parser.sale.data.length == 0) {
            if (parser.rent && parser.rent.data && parser.rent.data.length == 0) {
                return { code: 1, data: "Missing sale or rent" };
            }
        }

        const types = ['apartment', 'villa', 'room', 'land', 'warehouse', 'building', 'duplex', 'office', 'studio', 'offplan', 'commercial', 'store'];
        let foundType = false;

        for (const type of types) {
            if (parser[type] && parser[type].data && parser[type].data.length > 0) {
                foundType = true;
                break;
            }
        }

        if (!foundType) {
            return { code: 1, data: "At least one type like apartment / villa / land / studio should be found..." };
        }

        return { code: 0, data: "ok" };
    }

    const handleSubmit = () => {
        setAddlistingloading(true);
        setAddlistingresult('')

        let missing = missinginfo(parser)
        if (missing.code) {
            setAddlistingresult(missing.data);
            setAddlistingloading(false)
            return
        }

        if (!state.user) {
            setAddlistingresult('User not logged in');
            setAddlistingloading(false)
            return
        }

        if (!description) {
            setAddlistingresult('Description Missing');
            setAddlistingloading(false)
            return
        }
        if (!district) {
            setAddlistingresult('District Missing');
            setAddlistingloading(false)
            return
        }
        if (!type) {
            setAddlistingresult('Type Missing');
            setAddlistingloading(false)
            return
        }
        if (!agreement) {
            setAddlistingresult('Agreement Missing');
            setAddlistingloading(false)
            return
        }

        const addlistingFormData = new FormData();
        addlistingFormData.append('countryid', state.selectedcountry);
        addlistingFormData.append('userid', state.user.userid);
        addlistingFormData.append('description', description);
        addlistingFormData.append('district', district);
        addlistingFormData.append('community', community);
        addlistingFormData.append('type', type);
        addlistingFormData.append('agreement', agreement);
        for (let i = 0; i < media.length; i++) {
            addlistingFormData.append('file' + i, media[i]);
        }

        addlistingFormData.append('specs', JSON.stringify(serialize(specs)));

        axios.post(configData.SECUREURL + configData.ADDLISTING, addlistingFormData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setAddlistingresult('Success')
                }
                else {
                    setAddlistingresult(res.data.data)
                }
            }
        }).catch(() => {
            setAddlistingresult('Could not submit listing')
        }).finally(() => {
            setAddlistingloading(false)
        })
    }

    const handleSearch = (smartsearch) => {
        if (smartsearch !== '') {
            setSmartsearchloading(true);
            let result = suggestLocation(smartsearch, locations)
            setSmartsearchresult(result);
            setSmartsearchloading(false);
        }
    }

    const handleSmartClick = (smartitem) => {
        var selecteddistrict = fullmenu.filter((item) => item.id === smartitem.district)
        setCommunities(selecteddistrict[0].communities)
        setDistrict(smartitem.district)
        setCommunity(smartitem.community)
        setSmartsearch('')
        setSmartsearchresult([])
    }

    const handleDistrict = (e) => {
        setDistrict(e.target.value)
        const district = fullmenu.find(district => district.id === parseInt(e.target.value));
        if (district) {
            setCommunities(district.communities)
            setCommunity(district.communities[0]?.id)
        }
    }

    const handleDescription = (e) => {
        setDescription(e.target.value)

        if (isUnicode(e.target.value)) {
            setDescriptiondirectionrtl(true)
        }
        else {
            setDescriptiondirectionrtl(false)
        }
    }

    useEffect(() => {
        if (isUnicode(smartsearch)) {
            setSmartsearchdirectionrtl(true)
        }
        else {
            setSmartsearchdirectionrtl(false)
        }

        if (smartsearchtimeout) {
            clearTimeout(smartsearchtimeout)
        }

        setSmartsearchtimeout(setTimeout(() => {
            handleSearch(smartsearch)
        }, 700))
    }, [smartsearch])

    function Summary({ parser }) {
        if (typeof parser !== 'object') return null;
        const validKeys = ['apartment', 'building', 'contactnumber', 'duplex', 'land', 'links', 'office', 'price', 'rent', 'room', 'sale', 'size', 'studio', 'villa'];

        return (
            <div className='addlisting_summary'>
                {
                    Object.entries(parser).filter(([key, value]) => validKeys.includes(key) && value.data && value.data.length > 0).map(([key, value]) => (
                        <div key={key} className='addlisting_summarykey'>{key}: {value.data instanceof Array && value.data.map((string, key) => <div key={key} className='addlisting_summaryvalue'>{string}</div>)} </div>
                    ))
                }
                {
                    parserlocations instanceof Array && parserlocations.map((location, index) => (
                        <div key={'location' + index} className='addlisting_summarykey'>location:  {location.keyword}</div>
                    ))
                }
            </div>
        );
    }

    return (
        <div className='addlisting'>
            <Title text='Add Listing' />

            <textarea
                className={descriptiondirectionrtl ? 'addlisting_textarea addlisting_rtl' : 'addlisting_textarea addlisting_ltr'}
                name='description'
                rows='7'
                placeholder='Enter description'
                maxLength='5000'
                onChange={e => handleDescription(e)}
                value={description}
                required
                spellCheck={false}
            />

            {state.selectedcountry == 5 &&
                <input
                    className={smartsearchdirectionrtl ? 'addlisting_smartsearchinputrtl' : 'addlisting_smartsearchinputltr'}
                    placeholder='FAL License Information / رقم المعلن'
                    value={advertisingno}
                    onChange={e => setAdvertisingno(e.target.value)}
                    spellCheck={false}
                />
            }

            <Summary parser={parser} />

            <div className='addlisting_smartsearch'>
                <input
                    className={smartsearchdirectionrtl ? 'addlisting_smartsearchinputrtl' : 'addlisting_smartsearchinputltr'}
                    placeholder='Search locations'
                    value={smartsearch}
                    onChange={e => setSmartsearch(e.target.value)}
                    spellCheck={false}
                />
                {
                    smartsearchloading &&
                    <div className='addlisting_smartsearchspinner'>
                        <Spinner loading={true} size={15} />
                    </div>
                }
                {
                    (!smartsearchloading && smartsearchresult.length > 0) && (
                        <div className="addlisting_smartsearchclearbutton" onClick={() => { setSmartsearch(''); setSmartsearchresult([]) }}>
                            <img className='addlisting_smartsearchclearicon' src={addlisting_clearbutton} alt="Clear Button" />
                        </div>
                    )
                }
            </div>

            {
                smartsearchresult.length !== 0 &&
                <div className='addlisting_smartsearchcontainer'>
                    {
                        smartsearchresult.map(
                            (smartsearchitem, index) =>
                                <div
                                    key={'smartsearchitem' + index}
                                    className='addlisting_smartsearchitem'
                                    onClick={() => handleSmartClick(smartsearchitem)}
                                >
                                    {smartsearchitem.keyword}, {smartsearchitem.districtname}, {smartsearchitem.communityname}
                                </div>
                        )
                    }
                </div>
            }

            <select className='addlisting_select' value={district} onChange={e => handleDistrict(e)}>
                <option key={'option0'} value=''>Select District</option>
                {
                    fullmenu instanceof Array && fullmenu.map(
                        (district, index) =>
                            (<option key={'option' + index} value={district.id}>{district.name}</option>)
                    )
                }
            </select>
            <select className='addlisting_select' value={community} onChange={e => setCommunity(e.target.value)}>
                <option key={'option0'} value=''>Select Community</option>
                {
                    communities instanceof Array && communities.map(
                        (community, index) =>
                            (<option key={'option' + index} value={community.id}>{community.name}</option>)
                    )
                }
            </select>
            <select className='addlisting_select' value={type} onChange={e => setType(e.target.value)} >
                <option key={'option0'} value=''>Select Type</option>
                {
                    state.types.map(
                        (item, index) =>
                            (<option key={'option' + index} value={item.id}>{item.type}</option>)
                    )
                }
            </select>
            <select className='addlisting_select' value={agreement} placeholder='Rent/Sale' onChange={e => setAgreement(e.target.value)}>
                <option key={'option0'} value=''>Select Agreement</option>
                {
                    state.agreements.map(
                        (item, index) =>
                            (<option key={'option' + index} value={item.id}>{item.agreement}</option>)
                    )
                }
            </select>

            <button className='addlisting_morelessbutton' onClick={() => setMoreDetails(!moredetails)} >{moredetails ? 'Less details' : 'More details'}</button>

            {
                moredetails &&
                <SpecsComponent type={type} agreement={agreement} outputData={(arg) => setSpecs(arg)} />
            }

            <FileUploader
                media={media}
                setMedia={setMedia}
            />


            <div className='addlisting_resultcontainer'>
                {addlistingresult && addlistingresult}
            </div>

            <div className='addlisting_spinnercontainer'>
                <Spinner loading={addlistingloading} size={30} />
            </div>

            <button className='addlisting_submitbutton' onClick={() => handleSubmit()} type='button'>Submit</button>
        </div>
    )
}

export default AddListing;