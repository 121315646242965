import React, { useEffect, useState } from 'react';
import axios from 'axios';
import configData from '../Config.json';
import Title from '../Title';

import './AgreementsEditor.css';

//Revision frontend 9-10-2023

function AgreementsEditor({ state, setState }) {
    const [agreementseditor, setAgreementseditor] = useState([]);
    const [change, setChange] = useState(false);

    const [propertyagreementmenu, setPropertyagreementmenu] = useState(null);

    useEffect(() => {
        console.log('Trying to get agreements');
        axios.post(configData.SECUREURL + configData.GETPROPERTYAGREEMENTS, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Get agreements data received')
            console.log(res.data)
            if (res.data instanceof Array) {
                setAgreementseditor(res.data)
            }
        })
    }, [change]);

    const handletogglemenu = (agreement) => {
        setPropertyagreementmenu(<AgreementsOptions agreement={agreement} />)
    }

    function AgreementsOptions({ agreement }) {
        return (
            <div className='agreementeditor_optionscontainer'>
                <div className='typeeditor_optionscontent'>
                    <button className='agreementeditor_optionbutton' onClick={() => setPropertyagreementmenu(<AddAgreement agreement={agreement} />)}>Add new</button>
                    <button className='agreementeditor_optionbutton' onClick={() => setPropertyagreementmenu(<EditAgreement agreement={agreement} />)}>Edit</button>
                    <button className='agreementeditor_optionbutton' onClick={() => setPropertyagreementmenu(<RemoveAgreement agreement={agreement} />)}>Remove</button>
                    <button className='agreementeditor_optionbutton' onClick={() => setPropertyagreementmenu()}>Cancel</button>
                </div>
            </div>
        )
    }

    function AddAgreement() {
        const [agreementid, setAgreementid] = useState('');
        const [agreementname, setAgreementname] = useState('');

        const addAgreement = () => {
            console.log('Trying to add agreement');
            const data = {
                agreementid: agreementid,
                agreementname: agreementname
            };
            axios.post(configData.SECUREURL + configData.ADDPROPERTYAGREEMENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Add agreement data received')
                console.log(res.data)
                if (res.data.code === 1) {
                    setChange(!change)
                    setPropertyagreementmenu(null)
                }
            }).catch(() => {

            })
        }

        return (
            <div className='agreementeditor_optionscontainer'>
                <div className='typeeditor_optionscontent'>
                    <p className='agreementeditor_optiontitle'>Add new agreement</p>
                    <input className='agreementeditor_input' type='text' value={agreementid} onChange={e => setAgreementid(e.target.value)} />
                    <input className='agreementeditor_input' type='text' value={agreementname} onChange={e => setAgreementname(e.target.value)} />
                    <button className='agreementeditor_optionbutton'><span onClick={() => addAgreement()}>Add</span></button>
                    <button className='agreementeditor_optionbutton' onClick={() => setPropertyagreementmenu()}>Cancel</button>
                </div>
            </div>
        )
    }

    function EditAgreement({ agreement }) {
        const [agreementid, setAgreementid] = useState(agreement.id);
        const [agreementname, setAgreementname] = useState(agreement.agreement);

        const editAgreement = () => {
            console.log('Trying to edit agreement');
            const data = {
                agreementid: agreementid,
                agreementname: agreementname
            };
            axios.post(configData.SECUREURL + configData.UPDATEPROPERTYAGREEMENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Edit agreement data received')
                if (res.data.code === 1) {
                    setPropertyagreementmenu(null)
                }
                setChange(!change)
            }).catch(() => {

            })
        }

        return (
            <div className='agreementeditor_optionscontainer'>
                <div className='typeeditor_optionscontent'>
                    <p className='agreementeditor_optiontitle'>Edit agreement</p>
                    <input className='agreementeditor_input' type='text' value={agreementid} onChange={e => setAgreementid(e.target.value)} />
                    <input className='agreementeditor_input' type='text' value={agreementname} onChange={e => setAgreementname(e.target.value)} />
                    <button className='agreementeditor_optionbutton'><span onClick={() => editAgreement()}>Update</span></button>
                    <button className='agreementeditor_optionbutton' onClick={() => setPropertyagreementmenu()}>Cancel</button>
                </div>
            </div>
        )
    }

    function RemoveAgreement({ agreement }) {

        const removeAgreement = () => {
            console.log('Trying to remove agreement');
            const data = {
                agreementid: agreement.id
            };
            axios.post(configData.SECUREURL + configData.REMOVEPROPERTYAGREEMENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Remove agreement data received')
                if (res.data.code === 1) {
                    setChange(!change)
                    setPropertyagreementmenu(null)
                }
            }).catch(() => {

            })
        }

        return (
            <div className='agreementeditor_optionscontainer'>
                <div className='typeeditor_optionscontent'>
                    <p className='agreementeditor_optiontitle'>Are you sure you want to remove agreement?</p>
                    <button className='agreementeditor_optionbutton' onClick={() => removeAgreement()}>Yes</button>
                    <button className='agreementeditor_optionbutton' onClick={() => setPropertyagreementmenu()}>No</button>
                </div>
            </div>
        )
    }

    return (
        <div className='agreementeditor'>
            <Title text='Agreement Editor' />

            <table className='agreementeditor_table'>
                <thead><tr><th>Id</th><th>Agreement</th><th>Option</th></tr></thead>
                <tbody>
                    {
                        agreementseditor.map((agreement, index) =>
                            <tr key={'agreement' + index}>
                                <td>{agreement.id}</td>
                                <td>{agreement.agreement}</td>
                                <td><span className='agreementeditor_optionsbutton' onClick={() => handletogglemenu(agreement)}>...</span></td>
                            </tr>
                        )
                    }
                </tbody>
            </table>

            {propertyagreementmenu}

        </div>
    )
}

export default AgreementsEditor;