import React from 'react'
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { isUnicode } from './Utils/text.js';

import Profile from './Profile.js';

import './Listings.css';

function Listings({ state, setState }) {
    const history = useNavigate();

    const handleOpenListing = (listing) => {
        history(listing.id)
    }

    const handleExpandListing = (listing) => {
        setState(state => ({
            ...state,
            listings: state.listings.map(l =>
                l === listing ? { ...l, open: true } : l
            )
        }));
    }

    const handleLister = (listerid) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Profile state={state} setState={setState} listerid={listerid} /> }))
    }

    const handleSeeMore = (offset) => {
        setState(state => ({ ...state, selectedoffset: offset }))
    }

    return (
        <div className='listings'>
            {
                state.listings.map((listing, index) => {

                    return (
                        <div
                            key={'listing' + index}
                            className={listing.new ? 'listings_listing listings_listingsnew' : 'listings_listing'}
                            offset={listing.offset}
                            remaining={listing.remaining}
                        >
                            <div className='listings_listinginfo'>
                                <div
                                    className='listings_lister'
                                    onClick={() => handleLister(listing.listerid)}
                                >
                                    {listing.listername}
                                </div>
                                <div className='listings_seperator'> · </div>
                                <div className='listings_timestamp'>{moment(listing.timestamp * 1000).fromNow()}</div>
                                <div className='listings_seperator'> · </div>
                                <p className='listings_district'>{listing.district}</p>
                                <div className='listings_seperator'> · </div>
                                <p className='listings_community'>{listing.community}</p>
                                <div className='listings_seperator'> · </div>
                                <p className='listings_agreement'>{listing.agreement}</p>
                                <div className='listings_seperator'> · </div>
                                <p className='listings_type'>{listing.type}</p>
                                <div className='listings_seperator'> · </div>
                                <p className='listings_views'> ({listing.counter} Views)</p>
                                {
                                    listing.new === 1 &&
                                    <span className='listings_newflag'>new</span>
                                }
                            </div>
                            {
                                (!listing.open && listing.descriptionfiltered.length > 8) &&
                                <>
                                    <div
                                        className='listings_listingdescription'
                                        onClick={() => handleOpenListing(listing)}
                                    >
                                        {
                                            listing.descriptionfiltered.slice(0, 8).map((line, key) => (
                                                <div
                                                    key={'line' + key}
                                                    className={line.rtl === 1 ? 'listings_textdirectionalityright listings_textalignright' : 'listings_textdirectionalityleft listings_textalignleft'}
                                                >
                                                    {line.data}
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div
                                        className="listings_listingseemore"
                                        onClick={() => handleExpandListing(listing)}
                                    >
                                        See more
                                    </div>
                                </>
                            }
                            {
                                (listing.open || listing.descriptionfiltered.length <= 8) &&
                                <div
                                    className='listings_listingdescription'
                                    onClick={() => handleOpenListing(listing)}
                                >
                                    {
                                        listing.descriptionfiltered.map((line, key) => (
                                            <div
                                                key={'line' + key}
                                                className={line.rtl === 1 ? 'listings_textdirectionalityright listings_textalignright' : 'listings_textdirectionalityleft listings_textalignleft'}
                                            >
                                                {line.data}
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                            <div className='listings_listingimages'>
                                {
                                    listing.images instanceof Array && listing.images.slice(0, 2).map((image, index) =>
                                        <img
                                            className='listings_listingimage'
                                            key={'image' + listing.id + index}
                                            onClick={() => handleOpenListing(listing)}
                                            src={image}
                                            loading='lazy'
                                            alt=''
                                            width='300'
                                            height='300'
                                        />
                                    )
                                }
                            </div>
                            {
                                listing.comment &&
                                <div className='listings_comment'>
                                    <div className='listings_commentrow'>
                                        <div className='listings_commentavatarurl'>
                                            <img src={listing.comment.avatarUrl} alt='Avatar' />
                                        </div>
                                        <div className='listings_commentusername'>{listing.comment.userName}</div>
                                        <div className={isUnicode(listing.comment.text) ? 'listings_commenttextrtl' : 'listings_commenttextrtl'}>{listing.comment.text}</div>
                                    </div>
                                    <div className='listings_commentseeallcomments'>
                                        <div className='listings_commentseeallcommentstext' onClick={() => handleOpenListing(listing)}>See all comments</div>
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })
            }
            {
                state.listings.length > 0 && state.listings[state.listings.length - 1].remaining > 0 &&
                <div className='listings_seemorecontainer'>
                    <div
                        className='listings_seemore'
                        onClick={() => handleSeeMore(state.listings[state.listings.length - 1].offset)}
                        disabled={state.listingsloading}
                    >
                        {state.listingsloading ? 'Loading...' : 'See more'}
                    </div>
                </div>
            }
            {
                state.listings.length == 0 &&
                <div className='listings_nolistings'>
                    <div className='listings_nolistingstitle'>No Listings</div>
                    <div className='listings_nolistingsmessage'>TRY CHANGING FILTERS</div>
                </div>
            }
        </div>
    )
}

export default Listings;