import React, { useEffect } from 'react';

import modal_closebutton from './Assets/modal/modal_closebutton.png';

import './Modal.css';

//revision 20240903

const Modal = ({ state, setState }) => {
  useEffect(() => {
    if (state.modalopen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [state.modalopen]);

  if (!state.modalopen) return null;

  const handleClose = () => {
    setState(state => ({ ...state, modalopen: false, modalcontent: null }));
  }

  return (
    <div className="modal_overlay" onClick={handleClose}>
      <div className="modal_content" onClick={(e) => e.stopPropagation()}>
        <div className="modal_closebutton" onClick={handleClose}>
          <img className="modal_closebuttonimage" src={modal_closebutton} alt="Close" />
        </div>
        {state.modalcontent}
      </div>
    </div>
  );
};

export default Modal;