import React, { useState, useEffect } from 'react';
import Swipe from 'react-swipe';

import './MediaGallery.css'

function MediaGallery({ state, setState, images, index }) {
    const [selectedImageIndex, setSelectedImageIndex] = useState(index);

    useEffect(() => {
        const handleKeyDown = (event) => {
            switch (event.keyCode) {
                case 37: // left arrow key
                    setSelectedImageIndex((selectedIndex) =>
                        (selectedIndex - 1 + images.length) % images.length
                    );
                    break;
                case 39: // right arrow key
                    setSelectedImageIndex((selectedIndex) =>
                        (selectedIndex + 1) % images.length
                    );
                    break;
                default:
                    break;
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setSelectedImageIndex, images.length]);

    const openModal = (index) => {
        setSelectedImageIndex(index);
    };

    const closeModal = () => {
        setSelectedImageIndex(null);
        setState(state => ({ ...state, modalopen: false, modalcontent: null }))
    };

    const previousMedia = () => {
        setSelectedImageIndex(selectedImageIndex === 0 ? images.length - 1 : selectedImageIndex - 1);
    };
    const nextMedia = () => {
        setSelectedImageIndex(selectedImageIndex === images.length - 1 ? 0 : selectedImageIndex + 1);
    };

    return (
        <>

            <Swipe
                onSwipedLeft={nextMedia}
                onSwipedRight={previousMedia}
            >
                <div>
                    {
                        images.map((image, index) => (
                            <div
                                key={'imagescontainer' + index}
                                className='mediagallery_imageContainer'
                            >
                                <img
                                    className='mediagallery_image'
                                    src={image}
                                    alt={'Image' + index}
                                    onClick={() => openModal(index)}
                                    loading="lazy"
                                />
                            </div>
                        ))
                    }
                </div>
            </Swipe>

            <div
                className='mediagallery_modal'
                overlayclassname='mediagallery_modalOverlay'
                contentlabel='Selected Image'
            >
                <div className='mediagallery_closebutton' onClick={closeModal}><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-83.6 290.5c4.8 4.8 4.8 12.6 0 17.4l-40.5 40.5c-4.8 4.8-12.6 4.8-17.4 0L256 313.3l-66.5 67.1c-4.8 4.8-12.6 4.8-17.4 0l-40.5-40.5c-4.8-4.8-4.8-12.6 0-17.4l67.1-66.5-67.1-66.5c-4.8-4.8-4.8-12.6 0-17.4l40.5-40.5c4.8-4.8 12.6-4.8 17.4 0l66.5 67.1 66.5-67.1c4.8-4.8 12.6-4.8 17.4 0l40.5 40.5c4.8 4.8 4.8 12.6 0 17.4L313.3 256l67.1 66.5z"></path></svg></div>
                <div className='mediagallery_leftbutton' onClick={previousMedia}><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zm28.9-143.6L209.4 288H392c13.3 0 24-10.7 24-24v-16c0-13.3-10.7-24-24-24H209.4l75.5-72.4c9.7-9.3 9.9-24.8.4-34.3l-11-10.9c-9.4-9.4-24.6-9.4-33.9 0L107.7 239c-9.4 9.4-9.4 24.6 0 33.9l132.7 132.7c9.4 9.4 24.6 9.4 33.9 0l11-10.9c9.5-9.5 9.3-25-.4-34.3z"></path></svg></div>
                <div className='mediagallery_rightbutton' onClick={nextMedia}><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm-28.9 143.6l75.5 72.4H120c-13.3 0-24 10.7-24 24v16c0 13.3 10.7 24 24 24h182.6l-75.5 72.4c-9.7 9.3-9.9 24.8-.4 34.3l11 10.9c9.4 9.4 24.6 9.4 33.9 0L404.3 273c9.4-9.4 9.4-24.6 0-33.9L271.6 106.3c-9.4-9.4-24.6-9.4-33.9 0l-11 10.9c-9.5 9.6-9.3 25.1.4 34.4z"></path></svg></div>
                <div className='mediagallery_modalImageContainer'>
                    <img className='mediagallery_modalImage' src={images[selectedImageIndex]} alt={'Selected Image' + selectedImageIndex} />
                </div>
                <div className='mediagallery_options'>
                    <div className='mediagallery_option1'></div>
                    <div className='mediagallery_option1'>Image {selectedImageIndex + 1}</div>
                    <div className='mediagallery_option1'></div>
                </div>
            </div>
        </>
    );
}

export default MediaGallery;