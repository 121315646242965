import React, { useEffect, useState } from 'react';
import axios from 'axios';
import configData from '../Config.json';
import Title from '../Title';

import './SmartSearchEditor.css';

function SmartSearchEditor({ state, setState }) {
    const [fullmenu, setFullmenu] = useState([]);
    const [keywords, setKeywords] = useState([]);

    const [option, setOption] = useState(null);
    const [change, setChange] = useState(false);

    const ungroupedKeywords = keywords.filter(keyword =>
        !fullmenu.some(district =>
            district.communities && district.communities.some(community =>
                community.id === keyword.community
            )
        )
    );

    useEffect(() => {
        console.log('Trying to full menu');
        const data = {
            countryid: state.selectedcountry,
        };

        axios.post(configData.SECUREURL + configData.GETFULLMENU, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then(res => {
            console.log('Get full menu data received')
            console.log(res.data)
            if (res.data instanceof Array) {
                setFullmenu(res.data)
            }
        }).catch(() => {
            console.error('Error fetching fullmenu')
        })
    }, [change]);

    useEffect(() => {
        console.log('Trying to get keywords');
        const data = {
            countryid: state.selectedcountry
        };
        axios.post(configData.SECUREURL + configData.ADMINSMARTSEARCHGET, data, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Get keywords data received')
            console.log(res.data)
            if (res.data instanceof Array) {
                setKeywords(res.data)
            }
        }).catch(() => {
            console.error('Couldnt get keywords')
        })
    }, [change])

    const queryOpenStreetMap = async (locationString) => {
        const resultArray = [];
        const encodedLocationString = encodeURIComponent(locationString);
        const apiUrl = `https://nominatim.openstreetmap.org/search?format=json&q=${encodedLocationString}&accept-language=ar`;

        const response = await fetch(apiUrl);
        if (!response.ok) {
            console.error('Failed to fetch data from OpenStreetMap');
            return resultArray;
        }

        const data = await response.json();
        if (data instanceof Array) {
            if (data.length === 0) {
                console.error('No location found for the specified query');
                return resultArray;
            }

            data.forEach((item) => {
                const resultObj = {
                    displayname: item.display_name,
                    arabickeyword: item.name,
                    lat: item.lat,
                    long: item.lon
                };
                resultArray.push(resultObj);
            });
        }

        console.log(resultArray)
        return resultArray;
    };

    function AddKeyword({ fullmenu, districtid, communityid }) {
        const [keyword, setKeyword] = useState('');
        const [queryresults, setQueryresults] = useState([]);

        const [keywordarabic, setKeywordarabic] = useState('');
        const [districts, setDistricts] = useState([]);
        const [selecteddistrict, setSelecteddistrict] = useState('');
        const [communities, setCommunities] = useState([]);
        const [selectedcommunity, setSelectedcommunity] = useState('');

        const [latitude, setLatitude] = useState('');
        const [longitude, setLongitude] = useState('');

        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setDistricts(fullmenu)
            setSelecteddistrict(districtid)
        }, [districtid])

        useEffect(() => {
            let selectedDistrict = fullmenu.find((district) => district.id == selecteddistrict)
            if (selectedDistrict?.communities.length) {
                setCommunities(selectedDistrict.communities)
                setSelectedcommunity(selectedDistrict.communities[0].id)
                setSelectedcommunity(communityid)
            }
            else {
                setCommunities([])
                setSelectedcommunity('')
            }
        }, [selecteddistrict])

        const addKeyword = (keyword, keywordarabic, district, community, latitude, longitude) => {
            console.log('Trying to add keyword');
            setLoading(true);

            if (!keyword) {
                setLoading(false);
                return
            }

            keyword = keyword.trim();
            keywordarabic = keywordarabic.trim();

            const data = {
                keyword: keyword,
                keywordarabic: keywordarabic,
                country: state.selectedcountry,
                district: district,
                community: community,
                latitude: latitude,
                longitude: longitude
            };

            axios.post(configData.SECUREURL + configData.ADMINSMARTSEARCHADD, data, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Add keyword data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code == 1) {
                        setChange(!change)
                        setOption(null)
                    }
                }
            }).catch(() => {

            }).finally(() => {
                setLoading(false)
            })
        }

        const getOpenStreetMapData = async (keyword) => {
            try {
                const data = await queryOpenStreetMap(keyword);
                setQueryresults(data);
            } catch (error) {
                console.error('Error fetching OpenStreetMap data:', error);
            }
        }

        const handleSelectChange = (e) => {
            const selectedIndex = e.target.value;
            const selectedDataObj = queryresults[selectedIndex];
            setKeywordarabic(selectedDataObj.arabickeyword);
            setLatitude(selectedDataObj.lat);
            setLongitude(selectedDataObj.long);
        };

        return (
            <div className='smartsearcheditor_modal'>
                <div className='smartsearcheditor_modaltitle'>Add Keyword</div>

                <input
                    className='smartsearch_modalinput'
                    type='text'
                    value={keyword}
                    onChange={e => setKeyword(e.target.value)}
                    placeholder='Keyword'
                />

                <select
                    className='smartsearch_modalselect'
                    onChange={handleSelectChange}
                >
                    <option value=''>Please select</option>
                    {Array.isArray(queryresults) ? (
                        queryresults.map((data, index) => (
                            <option key={`query${index}`} value={index}>{data.displayname}</option>
                        ))
                    ) : (
                        <option value="">No results found</option>
                    )}
                </select>

                <input
                    className='smartsearch_modalinput'
                    type='text'
                    value={keywordarabic}
                    onChange={e => setKeywordarabic(e.target.value)}
                    placeholder='Arabic Keyword'
                />

                <select
                    className='smartsearch_modalselect'
                    value={selecteddistrict}
                    onChange={e => setSelecteddistrict(e.target.value)}
                >
                    <option value=''>Select District</option>
                    {
                        districts.map((district, index) =>
                            <option key={'district' + index} value={district.id}>{district.name}</option>
                        )
                    }
                </select>
                <select
                    className='smartsearch_modalselect'
                    value={selectedcommunity}
                    onChange={e => setSelectedcommunity(e.target.value)}
                >
                    <option value=''>Select Commuity</option>
                    {
                        communities.map((data, index) =>
                            <option key={'community' + index} value={data.id}>{data.name}</option>
                        )
                    }
                </select>
                <input className='smartsearch_modalinput' type='text' value={latitude} onChange={e => setLatitude(e.target.value)} placeholder='Latitude' />
                <input className='smartsearch_modalinput' type='text' value={longitude} onChange={e => setLongitude(e.target.value)} placeholder='Longitude' />
                <button className='smartsearch_modalbutton' onClick={() => getOpenStreetMapData(keyword)}>Query OpenStreetMap</button>
                <button
                    className='smartsearch_modalbutton'
                    onClick={() => addKeyword(keyword, keywordarabic, selecteddistrict, selectedcommunity, latitude, longitude)}
                    disabled={loading}
                >
                    {loading ? 'Saving...' : 'Save'}
                </button>
                <button className='smartsearch_modalbutton' onClick={() => setOption(null)} >Cancel</button>
            </div >
        )
    }

    function EditKeyword({ fullmenu, keyword }) {
        const [id, setId] = useState('');

        const [key, setKey] = useState('');

        const [queryresults, setQueryresults] = useState([]);
        const [keywordarabic, setKeywordarabic] = useState('');
        const [districts, setDistricts] = useState([]);
        const [selecteddistrict, setSelecteddistrict] = useState('');
        const [communities, setCommunities] = useState([]);
        const [selectedcommunity, setSelectedcommunity] = useState('');

        const [latitude, setLatitude] = useState('');
        const [longitude, setLongitude] = useState('');

        const [result, setResult] = useState(null);

        useEffect(() => {
            setId(keyword.id)
            setKey(keyword.keyword);
            setKeywordarabic(keyword.keywordarabic);
            setDistricts(fullmenu)
            setSelecteddistrict(keyword.district)
            setSelectedcommunity(keyword.community)
            setLatitude(keyword.latitude)
            setLongitude(keyword.longitude)
        }, [fullmenu, keyword])

        useEffect(() => {
            let selectedDistrict = fullmenu.find((district) => district.id == selecteddistrict)
            if (selectedDistrict?.communities.length) {
                setCommunities(selectedDistrict.communities)
                setSelectedcommunity(selectedDistrict.communities[0].id)
            }
            else {
                setCommunities([])
                setSelectedcommunity('')
            }
        }, [selecteddistrict, selectedcommunity])

        const updateKeyword = (keywordId, keyword, keywordarabic, district, community, latitude, longitude) => {
            console.log('Trying to update keyword');

            keyword = keyword.trim();
            keywordarabic = keywordarabic.trim();

            const data = {
                keywordid: keywordId,
                keyword: keyword,
                keywordarabic: keywordarabic,
                district: district,
                community: community,
                latitude: latitude,
                longitude: longitude
            };

            axios.post(configData.SECUREURL + configData.ADMINSMARTSEARCHUPDATE, data, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Update keyword data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code == 1) {
                        setChange(!change)
                        setOption(null)
                    }
                }
            })
        }

        const getOpenStreetMapData = async (keyword) => {
            try {
                const data = await queryOpenStreetMap(keyword);
                setQueryresults(data);
            } catch (error) {
                console.error('Error fetching OpenStreetMap data:', error);
            }
        }

        return (
            <div className='smartsearcheditor_modal'>
                <div className='smartsearcheditor_modaltitle'>Edit Smart Item</div>

                <input
                    className='smartsearch_modalinput'
                    type='text'
                    value={key}
                    onChange={e => setKey(e.target.value)}
                    placeholder='Keyword'
                />

                <input
                    className='smartsearch_modalinput'
                    type='text'
                    value={keywordarabic}
                    onChange={e => setKeywordarabic(e.target.value)}
                    placeholder='Arabic Keyword'
                />

                <select
                    className='smartsearch_modalselect'
                    value={selecteddistrict}
                    onChange={e => setSelecteddistrict(e.target.value)}
                >
                    <option value=''>Select District</option>
                    {
                        districts.map((district, index) =>
                            <option key={'district' + index} value={district.id}>{district.name}</option>
                        )
                    }
                </select>
                <select
                    className='smartsearch_modalselect'
                    value={selectedcommunity}
                    onChange={e => setSelectedcommunity(e.target.value)}
                >
                    <option value=''>Select Commuity</option>
                    {
                        communities.map((data, index) =>
                            <option key={'community' + index} value={data.id}>{data.name}</option>
                        )
                    }
                </select>

                <div>Latitude</div><input className='smartsearch_modalinput' type='text' value={latitude} onChange={e => setLatitude(e.target.value)} />
                <div>Longitude</div><input className='smartsearch_modalinput' type='text' value={longitude} onChange={e => setLongitude(e.target.value)} />
                <div>{result}</div>
                <button className='smartsearch_modalbutton' onClick={() => getOpenStreetMapData(keyword)}>Query OpenStreetMap</button>
                <button className='smartsearch_modalbutton' onClick={() => updateKeyword(id, key, keywordarabic, selecteddistrict, selecteddistrict, latitude, longitude)}>Update Keyword</button>
                <button className='smartsearch_modalbutton' onClick={() => setOption(<RemoveKeyword keyword={keyword} />)}>Remove</button>
                <button className='smartsearch_modalbutton' onClick={() => setOption()}>Cancel</button>
            </div>
        )
    }

    function RemoveKeyword({ keyword }) {
        const [result, setResult] = useState(null);

        const removeKeyword = (keywordId) => {
            console.log('Trying to remove keyword');
            const data = {
                keywordid: keywordId
            };
            axios.post(configData.SECUREURL + configData.ADMINSMARTSEARCHREMOVE, data, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Remove keyword data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setChange(!change)
                        setOption(null)
                    }
                }
            }).catch((error) => {
                setResult(error)
            })
        }

        return (
            <div className='smartsearcheditor_modal'>
                <p className='smartsearch_modaltitle'>Are you sure ?</p>
                <button className='smartsearch_modalbutton' onClick={() => removeKeyword(keyword.id)}>Yes</button>
                <button className='smartsearch_modalbutton' onClick={() => setOption(null)}>Cancel</button>
            </div>
        )
    }

    return (
        <div className='smartsearcheditor'>
            <Title text='Smart Search Editor' />

            <div className='smartsearcheditor_buttonscontainer'>
                <button className='smartsearcheditor_button' onClick={() => setOption(<AddKeyword fullmenu={fullmenu} districtid='' communitid='' />)}>Add Keyword</button>
                <button className='smartsearcheditor_button' onClick={null}>Save as Excel</button>
            </div>

            <div className='smartsearcheditor_summary'>
                <div>Total Entries: {keywords.length}</div>
            </div>

            <table className='smartsearcheditor_table'>
                <thead><tr><th>District</th><th>Community</th><th>ID</th></tr></thead>
                <tbody>
                    {
                        fullmenu.map((district, index) =>
                            <React.Fragment key={'district' + index}>
                                <tr
                                    className='smartsearcheditor_fullmenutabledistrict'
                                    onClick={() => setOption(<AddKeyword fullmenu={fullmenu} districtid={district.id} communitid='' />)}
                                >
                                    <td>{district.name}</td>
                                    <td></td>
                                    <td>{district.id}</td>
                                </tr>
                                {
                                    district.communities && district.communities.map((community, index) =>
                                        <React.Fragment key={'community' + index} >
                                            <tr
                                                className='smartsearcheditor_fullmenutablecommuity'
                                                onClick={() => setOption(<AddKeyword fullmenu={fullmenu} districtid={district.id} communityid={community.id} />)}
                                            >
                                                <td></td>
                                                <td>{community.name}</td>
                                                <td>{community.id}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>
                                                    {
                                                        keywords.filter(obj => obj.community === community.id).map((obj, index) => (
                                                            <div className='smartsearcheditor_fullmenutablecommunitykeywordcontainer' onClick={() => setOption(<EditKeyword fullmenu={fullmenu} keyword={obj} />)} key={'keyword' + index}>
                                                                <div className='smartsearcheditor_fullmenutablecommunitykeywordid'>{obj.id}</div>
                                                                <div className='smartsearcheditor_fullmenutablecommunitykeyword'>{obj.keyword}</div>
                                                                <div className='smartsearcheditor_fullmenutablecommunitykeyword'>{obj.keywordarabic}</div>
                                                            </div>
                                                        ))
                                                    }
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                }
                            </React.Fragment>
                        )
                    }
                    {ungroupedKeywords.length > 0 && (
                        <React.Fragment>
                            <tr className='smartsearcheditor_ungroupedheader'>
                                <td colSpan={3}>Ungrouped Keywords</td>
                            </tr>
                            {
                                ungroupedKeywords.map((obj, index) => (
                                    <tr key={'ungrouped' + index} className='smartsearcheditor_ungroupedkeyword' onClick={() => setOption(<EditKeyword fullmenu={fullmenu} keyword={obj} />)}>
                                        <td colSpan={3}>
                                            <div className='smartsearcheditor_fullmenutablecommunitykeywordcontainer'>
                                                <div className='smartsearcheditor_fullmenutablecommunitykeywordid'>{obj.id}</div>
                                                <div className='smartsearcheditor_fullmenutablecommunitykeyword'>{obj.keyword}</div>
                                                <div className='smartsearcheditor_fullmenutablecommunitykeyword'>{obj.keywordarabic}</div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </React.Fragment>
                    )}
                </tbody>
            </table>

            {option}

        </div >
    )
}

export default SmartSearchEditor;