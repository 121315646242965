import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import CommentsSection from './CommentsSection.js';

import axios from 'axios';
import configData from './Config.json';
import moment from 'moment';
import NoMatch from './NoMatch.js';

import Modal from './Modal.js';
import Spinner from './Spinner.js';
import SEO from './Metatags.js';
import CopyButton from './CopyButton.js';
import Profile from './Profile.js';
import Header from './Header.js';
import MediaGallery from './MediaGallery.js';

import './Listing.css';

function Listing({ state, setState }) {
    const { countryname, listingid } = useParams();

    const [id, setId] = useState(0);
    const [property, setProperty] = useState({});
    const [listerid, setListerid] = useState('');
    const [lister, setLister] = useState('');
    const [images, setImages] = useState([]);
    const [metatags, setMetatags] = useState({ title: '', description: '' });
    const [filtered, setFiltered] = useState(true);
    const [socialmediacommunities, setSocialmediacommunities] = useState([]);

    const [descriptionfiltered, setDescriptionfiltered] = useState([]);
    const [descriptionunfiltered, setDescriptionunfiltered] = useState([]);

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    const history = useNavigate();

    useEffect(() => {
        let countryId = null;

        if (listingid) {
            setState(state => ({ ...state, selectedlisting: listingid }));
        }

        if (countryname) {
            const filteredCountry = state.countries.filter(country => country.dir === countryname);
            if (filteredCountry.length > 0) {
                countryId = filteredCountry[0].id;
                setState(state => ({ ...state, selectedcountry: countryId }));
            }
        }

        setLoading(true);

        const data = {
            countryid: countryId,
            listingid: listingid
        };

        axios.post(configData.SECUREURL + configData.GETLISTING, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log(res.data);
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setError(false);
                    setListerid(res.data.data.listerid)
                    setLister(res.data.data.listername);
                    setProperty(res.data.data);
                    setDescriptionfiltered(res.data.data.descriptionfiltered)
                    setDescriptionunfiltered(res.data.data.descriptionunfiltered);
                    if (res.data.data.images != null) setImages(res.data.data.images);
                    setMetatags(res.data.data.metatags);
                    setSocialmediacommunities(res.data.socialmediacommunities)
                }
                else if (res.data.code === 0) {
                    setError(true);
                    setResult(res.data.data);
                }
            }
            setLoading(false);
        }).catch((err) => {
            console.log(err);
            setError(true);
            setResult('Please check connection and try again');
        }).finally(() => {
            setLoading(false);
        });

    }, [countryname, listingid])

    const toggleNonfiltered = () => {
        setFiltered(false)
    }

    const handleLister = (listerid) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Profile state={state} setState={setState} listerid={listerid} /> }))
    }

    const handleImage = (images, index) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <MediaGallery state={state} setState={setState} images={images} index={index} /> }))
    }

    return (
        <div className='listing'>
            <Header state={state} setState={setState} />

            {
                !loading &&
                <SEO
                    title={metatags.title}
                    description={metatags.description}
                    canonical={metatags.canonical}
                    robots='index'
                    image={metatags.ogimage}
                />
            }
            {
                loading ?
                    <div className='listing_spinnercontainer'>
                        <Spinner size={30} color="#686769" loading={true} />
                    </div>
                    :
                    (error ?
                        <NoMatch reason={result} listingid={state.selectedlisting} />
                        :
                        (Object.keys(property).length > 0 && <>
                            <div key='listing_info' className='listing_info'>
                                <div className='listing_cols'>
                                    <div className='listing_infocol1'>
                                        <span>Property ID: {state.selectedlisting}</span>
                                    </div>
                                    <div className='listing_infocol2'>
                                        <span className='listing_infoauthor' onClick={() => handleLister(listerid)}>{lister}</span>
                                        <span> · </span>
                                        <span>{property.timestamp && moment(property.timestamp * 1000).fromNow()}</span>
                                        <span> · </span>
                                        <span>{property.districtname}</span>
                                        <span> · </span>
                                        <span>{property.communityname}</span>
                                        <span> · </span>
                                        <span>{property.agreement === 1 ? 'Sale' : 'Rent'}</span>
                                        <span> · </span>
                                        <span>{property.typename}</span>
                                        <span> · </span>
                                        <span>Viewed ({property.counter} times)</span>
                                    </div>
                                    <div className='listing_infocol4'>
                                        <span className='listing_datetime'>{property.timestamp && moment(property.timestamp * 1000).format("MMMM Do YYYY, h:mm:ss a")}</span>
                                    </div>
                                </div>
                            </div>
                            <div key='listing_description' className="listing_description">
                                {
                                    filtered ?
                                        descriptionfiltered.map((line, key) => (
                                            <div
                                                key={'line' + key}
                                                className={line.rtl === 1 ? 'listings_textdirectionalityright listings_textalignright' : 'listings_textdirectionalityleft listings_textalignleft'}
                                            >
                                                {line.data}
                                            </div>
                                        ))
                                        :
                                        descriptionunfiltered.map((line, key) => (
                                            <div
                                                key={'line' + key}
                                                className={line.rtl === 1 ? 'listings_textdirectionalityright listings_textalignright' : 'listings_textdirectionalityleft listings_textalignleft'}
                                            >
                                                {line.data}
                                            </div>
                                        ))
                                }
                                {
                                    property.phone &&
                                    <div
                                        className='listing_seephonenumbers'
                                        onClick={toggleNonfiltered}>See phone numbers
                                    </div>
                                }
                                {
                                    socialmediacommunities.map((link, index) => {
                                        const capitalizeFirstLetter = (string) => {
                                            return string.charAt(0).toUpperCase() + string.slice(1);
                                        };

                                        return (
                                            <div key={'link' + index}>
                                                <a href={link.url} target="_blank" rel="noopener noreferrer">
                                                    {capitalizeFirstLetter(link.type)}: {link.name || link.url}
                                                </a>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            {
                                property.specs && property.specs.length !== 0 &&
                                <div className='listing_tablecontainer'>
                                    <table className='listing_table'>
                                        <tbody>
                                            {
                                                (Object.entries(property.specs).map(([key, value], index) => {
                                                    return (
                                                        <tr key={'tr' + index} className='listing_row'>
                                                            <td>{key}</td>
                                                            <td>{value}</td>
                                                        </tr>
                                                    );
                                                }))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }

                            <div className='listing_images'>
                                {
                                    images.map((image, index) =>
                                        <div
                                            className='listing_imagecontainer'
                                            key={'image' + index}
                                            onClick={() => handleImage(images, index)}
                                        >
                                            <img
                                                className='listing_image'
                                                src={image}
                                                loading='lazy'
                                                alt=''
                                            />
                                        </div>
                                    )
                                }
                            </div>

                            <div className='listing_optionssection'>
                                <CopyButton title={metatags.title} text={metatags.description} url={metatags.canonical} />
                            </div>

                            <div className='listing_commentsection'>
                                <CommentsSection state={state} pageId={id} />
                            </div>
                        </>)
                    )
            }

            <Modal state={state} setState={setState} />
        </div>
    )
}

export default Listing;