import React, { useState, useEffect } from 'react';
import axios from 'axios';
import configData from '../Config.json';
import Title from '../Title';

import './MetatagsEditor.css';

function MetatagsEditor({ state }) {
    const [metaTags, setMetaTags] = useState([]);

    const [option, setOption] = useState();

    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState('');

    const [change, setChange] = useState(false);

    const MAX_TITLE_LENGTH = 60;
    const MAX_DESCRIPTION_LENGTH = 160;

    useEffect(() => {
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                handleCloseModal();
            }
        };

        document.addEventListener('keydown', handleEscapeKey);

        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, []);

    useEffect(() => {
        console.log('Trying to get metatags');
        setLoading(true)

        const data = {
            countryid: state.id
        };

        axios.post(configData.SECUREURL + configData.GETMETATAGS, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Received metatags data')
            console.log(res.data)
            if (res.data instanceof Object) {
                setMetaTags(res.data)
            }
            setLoading(false)
        }).catch(() => {
            setLoading(false)
            setResult('Error')
        })
    }, [change])

    const handleAdd = (countryid, key, title, description, keywords) => {
        console.log('Trying to add metatag');
        setLoading(true)

        const data = {
            countryid: countryid,
            key: key,
            title: title,
            description: description,
            keywords: keywords
        };

        axios.post(configData.SECUREURL + configData.ADDMETATAG, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Received add metatag data')
            console.log(res.data)
            if (res.data instanceof Object) {
                setOption(false);
                setChange(!change);
            }
            setLoading(false)
        }).catch(() => {
            setLoading(false)
            setResult('Error')
        })
    };

    const handleUpdate = (countryid, key, title, description, keywords) => {
        console.log('Trying to update metatag');
        setLoading(true)

        const data = {
            countryid: countryid,
            key: key,
            title: title,
            description: description,
            keywords: keywords
        };

        axios.post(configData.SECUREURL + configData.UPDATEMETATAG, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Received update metatag data')
            console.log(res.data)
            if (res.data instanceof Object) {
                setOption(false);
                setChange(!change);
            }
            setLoading(false)
        }).catch(() => {
            setLoading(false)
            setResult('Error')
        })
    };

    const handleRemove = (countryid, key) => {
        console.log('Trying to remove metatag');
        setLoading(true)

        const data = {
            countryid: countryid,
            key: key
        };

        axios.post(configData.SECUREURL + configData.REMOVEMETATAG, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Received remove metatag data')
            console.log(res.data)
            if (res.data instanceof Object) {
                setOption(false);
            }
            setLoading(false)
        }).catch(() => {
            setLoading(false)
            setResult('Error')
        })
    };

    const handleCloseModal = () => {
        setOption(false);
    };

    function AddMetaTag({ tag }) {
        const [key, setKey] = useState('');
        const [title, setTitle] = useState('');
        const [description, setDescription] = useState('');
        const [keywords, setKeywords] = useState('');

        return (
            <div className='adminmetatagseditor_modal'>
                <div className='adminmetatagseditor_modalcontent'>
                    <span className='adminmetatagseditor_modalclose' onClick={handleCloseModal}>
                        &times;
                    </span>
                    <h2 className='adminmetatagseditor_modaltitle'>Add Meta Tag</h2>
                    <label className='adminmetatagseditor_modallabel' htmlFor='key'>Key:</label>
                    <input
                        className='adminmetatagseditor_modalinput'
                        type='text'
                        id='key'
                        value={key}
                        onChange={(e) => setKey(e.target.value)}
                    />
                    <label className='adminmetatagseditor_modallabel' htmlFor='title'>Title:</label>
                    <input
                        className='adminmetatagseditor_modalinput'
                        type='text'
                        id='title'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <p>Character count: {title.length}/{MAX_TITLE_LENGTH}</p>
                    <label className='adminmetatagseditor_modallabel' htmlFor='description'>Description:</label>
                    <textarea
                        className='adminmetatagseditor_modaltextarea'
                        id='description'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <p>Character count: {description.length}/{MAX_DESCRIPTION_LENGTH}</p>
                    <label className='adminmetatagseditor_modallabel' htmlFor='keywords'>Keywords:</label>
                    <textarea
                        className='adminmetatagseditor_modaltextarea'
                        id='keywords'
                        value={keywords}
                        onChange={(e) => setKeywords(e.target.value)}
                    />
                    <div className='adminmetatagseditor_modalbuttons'>
                        <button className='adminmetatagseditor_modalbuttonadd' onClick={() => handleAdd(state.id, key, title, description, keywords)}>Add</button>
                        <button className='adminmetatagseditor_modalbuttonclose' onClick={handleCloseModal}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    function EditMetaTag({ tag, metaKey }) {
        const [title, setTitle] = useState(tag.title);
        const [description, setDescription] = useState(tag.description);
        const [keywords, setKeywords] = useState(tag.keywords);

        return (
            <div className='adminmetatagseditor_modal'>
                <div className='adminmetatagseditor_modalcontent'>
                    <span className='adminmetatagseditor_modalclose' onClick={handleCloseModal}>
                        &times;
                    </span>
                    <h2 className='adminmetatagseditor_modaltitle'>Edit Meta Tag</h2>
                    <label className='adminmetatagseditor_modallabel' htmlFor='key'>Key: {metaKey}</label>
                    <label className='adminmetatagseditor_modallabel' htmlFor='title'>Title:</label>
                    <input
                        className='adminmetatagseditor_modalinput'
                        type='text'
                        id='title'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <p>Character count: {title.length}/{MAX_TITLE_LENGTH}</p>
                    <label className='adminmetatagseditor_modallabel' htmlFor='description'>Description:</label>
                    <textarea
                        className='adminmetatagseditor_modaltextarea'
                        id='description'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <p>Character count: {description.length}/{MAX_DESCRIPTION_LENGTH}</p>
                    <label className='adminmetatagseditor_modallabel' htmlFor='keywords'>Keywords:</label>
                    <textarea
                        className='adminmetatagseditor_modaltextarea'
                        id='keywords'
                        value={keywords}
                        onChange={(e) => setKeywords(e.target.value)}
                    />
                    <div className='adminmetatagseditor_modalbuttons'>
                        <button className='adminmetatagseditor_modalbuttonupdate' onClick={() => handleUpdate(state.id, metaKey, title, description, keywords)}>Update</button>
                        <button className='adminmetatagseditor_modalbuttonremove' onClick={() => handleRemove(state.id, metaKey)}>Remove</button>
                        <button className='adminmetatagseditor_modalbuttonclose' onClick={handleCloseModal}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='adminmetatagseditor'>
            
            <Title text='Metatags Editor' />

            <div className='adminmetatagseditor_buttonaddcontainer'>
                <button
                    className='adminmetatagseditor_buttonadd'
                    onClick={() => setOption(<AddMetaTag />)}
                >
                    Add Meta Tag
                </button>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Meta</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.keys(metaTags).map((key) => {
                            const tag = metaTags[key];
                            return (
                                <tr key={key}>
                                    <td>
                                        <div
                                            className='adminmetatagseditor_editbutton'
                                            onClick={() => setOption(<EditMetaTag tag={tag} metaKey={key} />)}>
                                            {key}
                                        </div>
                                    </td>
                                    <td>
                                        <h3>{tag.title}</h3>
                                        <p>{tag.description}</p>
                                        <p>{tag.keywords}</p>
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
            {
                option
            }
        </div >
    );
}

export default MetatagsEditor;