import React from 'react';
import './Spinner.css';

function Spinner({ loading, size }) {
  return (
    loading && (
      <div className="spinner_container">
        <div className="spinner_spinner" style={{ width: size, height: size }}></div>
      </div>
    )
  );
}

export default Spinner;