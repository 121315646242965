import React from 'react';
import Title from './Title';

import './Communities.css';

//revision 20240422

function Communities({ state, setState }) {

    const handleCommunity = (community) => {
        setState(state => ({ ...state, selectedcommunity: community.id }));
        setState(state => ({ ...state, modalopen: false, modalcontent: null }));
    }

    return (
        <div className="communities">
            <Title text='Communities' />
            {
                state.communities.map((community, index) =>
                    <div
                        key={'community' + index}
                        className={state.selectedcommunity == community.id ? 'communities_community communities_communityselected' : 'communities_community'}
                        onClick={() => handleCommunity(community)}
                    >
                        {community.name} ({community.no})
                    </div>
                )
            }
        </div>
    );
}

export default Communities;