import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import configData from '../Config.json';
import Spinner from '../Spinner';
import Title from '../Title';

import UserEdit from './UserEdit';

import './Users.css';

function Users({ state, setState }) {
    const [users, setUsers] = useState([]);

    const [userscount, setUserscount] = useState(0);
    const [offset, setOffset] = useState(0);
    const [searchstring, setSearchstring] = useState('');

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getUsersInfo();
        fetchItems('', 0)
    }, []);

    const getUsersInfo = () => {
        console.log('Trying to get users info');
        setLoading(true);

        axios.post(configData.SECUREURL + configData.GETUSERSINFO, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Users info data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setUserscount(res.data.data)
                }
            }
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }

    const fetchItems = async (keyword, offset) => {
        console.log('Trying to get search result');
        setLoading(true);

        const data = {
            searchstring: keyword,
            offset: offset
        };
        axios.post(configData.SECUREURL + configData.GETUSERS, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Search result received')
            console.log(res.data)
            if (res.data instanceof Array) {
                if (offset) setUsers([...users, ...res.data]);
                else setUsers(res.data);
            }
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    };

    const searchHandler = () => {
        fetchItems(searchstring, 0)
    }

    const handleLoadMore = () => {
        console.log(users.length)
        setOffset(users.length);
    };

    useEffect(() => {
        fetchItems(searchstring, offset);
    }, [offset]);


    const handleEditUser = (userId) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <UserEdit state={state} setState={setState} userId={userId} /> }))
    };

    return (
        <div className='adminuserlist'>

            <Title text='User List' />

            <div className='adminuserlist_users'>Total number of users {userscount}</div>

            <input
                className='adminuserlist_input'
                type='text'
                value={searchstring}
                onChange={e => setSearchstring(e.target.value)}
                placeholder='Search by USERID or USERNAME'
                onKeyDown={
                    event => {
                        if (event.key === 'Enter') {
                            searchHandler(event)
                        }
                    }
                }
            />
            <table className='adminuserlist_table'>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Created</th>
                        <th>Email</th>
                        <th>Userid</th>
                        <th>Username</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Last Login</th>
                        <th>Token</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        users.length !== 0 ?
                            (users.map((user, index) => {
                                return (
                                    <tr key={'tr' + index}>
                                        <td>{user.id}</td>
                                        <td>{moment(user.ts * 1000).format("MMMM Do YYYY, h:mm:ss a")}</td>
                                        <td>{user.useremail}</td>
                                        <td>{user.userid}</td>
                                        <td>{user.username}</td>
                                        <td>{user.usertype}</td>
                                        <td>{user.status}</td>
                                        <td>{user && user.usertoken && moment.unix(JSON.parse(user.usertoken).ts).format("MMMM Do YYYY, h:mm:ss a")}</td>
                                        <td>{user && user.usertoken && JSON.parse(user.usertoken).confirm}</td>
                                        <td><span className='adminuserlist_editButton' onClick={() => handleEditUser(user.id)}>Edit</span></td>
                                    </tr>
                                );
                            }
                            ))
                            :
                            <tr><td colSpan='9'>No Users</td></tr>
                    }
                </tbody>
            </table>

            <div className='users_loadmorebuttoncontainer'>
                <button className='users_loadmorebutton' onClick={handleLoadMore}>Load more</button>
            </div>
        </div>
    )
}

export default Users;