import React, { useEffect, useState } from 'react';
import axios from 'axios';
import configData from '../Config.json';
import Title from '../Title';

import './MenuEditor.css';

//Revision 11-10-2023 frontend

function MenuEditor({ state, setState }) {
    const [menu, setMenu] = useState([]);
    const [opencommunities, setOpencommunities] = useState(null);
    
    const [change, setChange] = useState(false);

    const [option, setOption] = useState(null);

    useEffect(() => {
        console.log('Trying to get full menu');
        const data = {
            countryid: state.selectedcountry
        };
        axios.post(configData.SECUREURL + configData.GETFULLMENU, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Get full menu data received')
            console.log(res.data)
            if (res.data instanceof Array) {
                setMenu(res.data)
            }
        }).catch(()=>{
            
        })
    }, [change, state.selectedcountry])

    function DistrictOptions({ district }) {
        return (
            <div className='menueditor_modal'>
                <div className='menueditor_modalcontent'>
                    <button className='menueditor_modalbutton' onClick={() => setOption(<AddDistrict districtId={district.id} />)}>Add New District</button>
                    <button className='menueditor_modalbutton' onClick={() => setOption(<AddCommunity district={district} />)}>Add New Community</button>
                    <button className='menueditor_modalbutton' onClick={() => setOption(<RemoveDistrict districtId={district.id} />)}>Remove District</button>
                    <button className='menueditor_modalbutton' onClick={() => setOption(<EditDistrict districtId={district.id} districtName={district.name} />)}>Edit District</button>
                    <button className='menueditor_modalbutton' onClick={() => setOption(null)}>Close</button>
                </div>
            </div>
        )
    }

    function AddDistrict({ districtId }) {
        const [districtid, setDistrictid] = useState();
        const [districtname, setDistrictname] = useState();

        const [result, setResult] = useState();
        const [loading, setLoading] = useState(false);

        const addDistrict = (districtId, districtid, districtname) => {
            console.log('Trying to add district');
            setLoading(true)
            
            districtname = districtname.trim();

            const data = {
                countryid: state.selectedcountry,
                districtid: districtId,
                newdistrictid: districtid,
                newdistrictname: districtname
            };
            axios.post(configData.SECUREURL + configData.ADDDISTRICT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Add district data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setChange(!change)
                        setResult(null)
                        setOption(null)
                    }
                    else setResult(res.data.data)
                }
            }).catch(() => {

            }).finally(() => {
                setLoading(false)
            })
        }

        return (
            <div className='menueditor_modal'>
                <div className='menueditor_modalcontent'>
                    <p className='menueditor_modaltitle'>Add new district</p>
                    <input className='menueditor_modalinput' type='text' value={districtid} onChange={e => setDistrictid(e.target.value)} placeholder='District ID' />
                    <input className='menueditor_modalinput' type='text' value={districtname} onChange={e => setDistrictname(e.target.value)} />

                    <button
                        className='menueditor_modalbutton'
                        onClick={() => addDistrict(districtId, districtid, districtname)}
                        disabled={loading}
                    >
                        Add
                    </button>
                    <button className='menueditor_modalbutton' onClick={() => setOption()}>Cancel</button>
                </div>
            </div >
        )
    }

    function EditDistrict({ districtId, districtName }) {
        const [districtid, setDistrictid] = useState(districtId);
        const [districtname, setDistrictname] = useState(districtName);

        const [result, setResult] = useState();
        const [loading, setLoading] = useState(false);

        const updateDistrict = (districtId, districtid, districtname) => {
            console.log('Trying to update district');
            setLoading(true)
            
            districtname = districtname.trim();

            const data = {
                countryid: state.selectedcountry,
                districtid: districtId,
                newdistrictid: districtid,
                newdistrictname: districtname,
            };
            axios.post(configData.SECUREURL + configData.UPDATEDISTRICT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Update district data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setChange(!change)
                        setResult(null)
                        setOption(null)
                    }
                    else setResult(res.data.data)
                }
            }).catch(() => {

            }).finally(() => {
                setLoading(false)
            })
        }

        return (
            <div className='menueditor_modal'>
                <div className='menueditor_modalcontent'>
                    <p className='menueditor_modaltitle'>Edit District</p>
                    <input className='menueditor_modalinput' type='text' value={districtid} onChange={e => setDistrictid(e.target.value)} />
                    <input className='menueditor_modalinput' type='text' value={districtname} onChange={e => setDistrictname(e.target.value)} />
                    <button
                        className='menueditor_modalbutton'
                        onClick={() => updateDistrict(districtId, districtid, districtname)}
                        disabled={loading}
                    >
                        Update
                    </button>
                    <button className='menueditor_modalbutton' onClick={() => setOption()}>Cancel</button>
                </div>
            </div>
        )
    }

    function RemoveDistrict({ districtId }) {
        const [result, setResult] = useState();

        const removeDistrict = (districtId) => {
            console.log('Trying to remove district');
            const data = {
                countryid: state.selectedcountry,
                districtid: districtId
            };
            axios.post(configData.SECUREURL + configData.REMOVEDISTRICT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Remove district data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setChange(!change)
                        setOption(null)
                    }
                    else setResult(res.data.data)
                }
            })
        }

        return (
            <div className='menueditor_modal'>
                <div className='menueditor_modalcontent'>
                    <p className='menueditor_modaltitle'>Are you sure ?</p>
                    <button className='menueditor_modalbutton'><span onClick={() => removeDistrict(districtId)}>Yes</span></button>
                    <button className='menueditor_modalbutton' onClick={() => setOption()}>Cancel</button>
                </div>
            </div>
        )
    }

    function CommunityOptions({ district, community }) {
        return (
            <div className='menueditor_modal'>
                <div className='menueditor_modalcontent'>
                    <button className='menueditor_modalbutton' onClick={() => setOption(<AddCommunity district={district} community={community} />)}>Add Community</button>
                    <button className='menueditor_modalbutton' onClick={() => setOption(<RemoveCommunity communityId={community.id} />)}>Remove Community</button>
                    <button className='menueditor_modalbutton' onClick={() => setOption(<EditCommunity communityId={community.id} communityName={community.name} />)}>Edit Community</button>
                    <button className='menueditor_modalbutton' onClick={() => setOption(null)}>Close</button>
                </div>
            </div>
        )
    }

    function AddCommunity({ district, community }) {
        const [communityid, setCommunityid] = useState('');
        const [communityname, setCommunityname] = useState('');

        const [result, setResult] = useState();
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            if (district.communities) {
                const communities = district.communities;

                const existingIds = new Set(communities.map((community) => community.id));

                const nextId = findNearestMissingId(existingIds, district.id + 1);

                setCommunityid(nextId);
            }
            else {
                setCommunityid(district.id + 1)
            }
        }, []);

        function findNearestMissingId(existingIds, startId) {
            for (let id = startId; ; id++) {
                if (!existingIds.has(id)) {
                    return id;
                }
            }
        }

        const addCommunity = (districtId, communityid, communityname) => {
            console.log('Trying to add community');
            setLoading(true)
            
            communityname = communityname.trim();

            const data = {
                countryid: state.selectedcountry,
                districtid: districtId,
                newcommunityid: communityid,
                newcommunityname: communityname
            };
            axios.post(configData.SECUREURL + configData.ADDDISTRICTCOMMUNITY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Add district data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setChange(!change)
                        setResult(null)
                        setOption(null)
                    }
                    else setResult(res.data.data)
                }
            }).catch((error) => {
                setResult(error)
            }).finally(() => {
                setLoading(false)
            })
        }

        return (
            <div className='menueditor_modal'>
                <div className='menueditor_modalcontent'>
                    <p className='menueditor_modaltitle'>Add new community</p>
                    <input className='menueditor_modalinput' type='text' value={communityid} onChange={e => setCommunityid(e.target.value)} />
                    <input className='menueditor_modalinput' type='text' value={communityname} onChange={e => setCommunityname(e.target.value)} />
                    <button
                        className='menueditor_modalbutton'
                        onClick={() => addCommunity(district.id, communityid, communityname)}
                        disabled={loading}
                    >
                        Add
                    </button>
                    <button className='menueditor_modalbutton' onClick={() => setOption()}>Cancel</button>
                </div>
            </div>
        )
    }

    function EditCommunity({ communityId, communityName }) {
        const [communityid, setCommunityid] = useState(communityId);
        const [communityname, setCommunityname] = useState(communityName);

        const [result, setResult] = useState();

        const updateCommunity = (communityId, communityid, communityname) => {
            console.log('Trying to update community');
            
            communityname = communityname.trim();

            const data = {
                countryid: state.selectedcountry,
                communityid: communityId,
                newcommunityid: communityid,
                newcommunityname: communityname
            };
            axios.post(configData.SECUREURL + configData.UPDATECOMMUNITY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Update community data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setChange(!change)
                        setOption(null)
                    }
                    else setResult(res.data.data)
                }
            })
        }
        return (
            <div className='menueditor_modal'>
                <div className='menueditor_modalcontent'>
                    <p className='menueditor_modaltitle'>Edit Community</p>
                    <input className='menueditor_modalinput' type='text' value={communityid} onChange={e => setCommunityid(e.target.value)} />
                    <input className='menueditor_modalinput' type='text' value={communityname} onChange={e => setCommunityname(e.target.value)} />
                    <button className='menueditor_modalbutton'><span onClick={() => updateCommunity(communityId, communityid, communityname)}>Update</span></button>
                    <button className='menueditor_modalbutton' onClick={() => setOption()}>Cancel</button>
                </div>
            </div>
        )
    }

    function RemoveCommunity({ communityId }) {
        const [result, setResult] = useState();
        
        const removeCommunity = (communityId) => {
            console.log('Trying to remove community');
            const data = {
                countryid: state.selectedcountry,
                communityid: communityId
            };
            axios.post(configData.SECUREURL + configData.REMOVECOMMUNITY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Remove community data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setChange(!change)
                        setOption(null)
                    }
                    else setResult(res.data.data)
                }
            }).catch((error) => {
                setResult(error)
            })
        }
        return (
            <div className='menueditor_modal'>
                <div className='menueditor_modalcontent'>
                    <p className='menueditor_modaltitle'>Are you sure ?</p>
                    <button className='menueditor_modalbutton'><span onClick={() => removeCommunity(communityId)}>Remove</span></button>
                    <button className='menueditor_modalbutton' onClick={() => setOption()}>Cancel</button>
                </div>
            </div>
        )
    }

    const openCommunities = (index) => {
        if (index === opencommunities) setOpencommunities(null)
        else setOpencommunities(index)
    }

    return (
        <div className='menueditor'>

            <Title text='Menu Editor' />

            <table className='menueditor_table'>
                <thead><tr><th></th><th>Id</th><th>Name</th><th>No</th><th></th></tr></thead>
                <tbody>
                    {
                        menu.map((district, index) => {
                            return (
                                <React.Fragment key={'district' + index}>
                                    <tr className='menueditor_districtitem'>
                                        <td className='menueditor_openbutton' onClick={() => openCommunities(index)}><span>{opencommunities === index ? '-' : '+'}</span></td>
                                        <td>{district.id}</td>
                                        <td>{district.name}</td>
                                        <td></td>
                                        <td className='menueditor_openbutton' onClick={() => setOption(<DistrictOptions district={district} />)}><span>...</span></td>
                                    </tr>
                                    {
                                        opencommunities === index && district.communities && district.communities.map((community, index) =>
                                            <tr key={'community' + index} className='menueditor_communityitem'>
                                                <td></td>
                                                <td>{community.id}</td>
                                                <td>{community.name}</td>
                                                <td>{community.no}</td>
                                                <td className='menueditor_openbutton' onClick={() => setOption(<CommunityOptions district={district} community={community} />)}><span>...</span></td>
                                            </tr>
                                        )
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                </tbody>
            </table>

            {option}

        </div>
    )
}

export default MenuEditor;